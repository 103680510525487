import React from 'react'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'
import {
  AdminCreatePropertyDownDetailDocument,
  AdminGetPropertyOccupancyDataDocument,
  Core_CreatePropertyDownDetailRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'
import { cleanTimeFromDatetime } from '../../utils'

type CreateDownDetailParams = {
  effectiveStartDate: string
  effectiveEndDate: string
}

type CreateDownDetailModalParams = {
  propertyUuid: string
  isOpen: boolean
  close: () => void
}

const CreateDownDetailModal = (props: CreateDownDetailModalParams) => {
  const { propertyUuid, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createDownDetail, { loading }] = useMutation(
    AdminCreatePropertyDownDetailDocument
  )

  const onSubmit = async (createDownDetailParams: CreateDownDetailParams) => {
    const { effectiveStartDate, effectiveEndDate } = createDownDetailParams
    const effectiveEndDateInput = effectiveEndDate
      ? cleanTimeFromDatetime(effectiveEndDate)
      : null

    const createDownDetailInput: Core_CreatePropertyDownDetailRequestInput = {
      downDetail: {
        propertyUuid,
        effectiveStartDate: cleanTimeFromDatetime(effectiveStartDate),
        effectiveEndDate: effectiveEndDateInput,
      },
    }

    try {
      await createDownDetail({
        variables: {
          input: createDownDetailInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify('Successfully created property downDetail.', 'success')
      handleClose()
    } catch (e) {
      notify('Failed to create property downDetail.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Create DownDetail"
      inputConfigs={[
        {
          inputName: 'effectiveStartDate',
          inputType: InputType.DatePicker,
          renderOn: 'true',
          required: true,
          props: {
            label: 'EffectiveStartDate',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'effectiveEndDate',
          inputType: InputType.DatePicker,
          renderOn: 'true',
          props: {
            label: 'EffectiveEndDate',
            fieldProps: {
              fullWidth: true,
            },
          },
        },
      ]}
    />
  )
}

export default CreateDownDetailModal
