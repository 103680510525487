import {
  CircleCheckedIcon,
  CircleUncheckedIcon,
  CloseIcon,
} from '@flock/shared-ui'
import { Box, IconButton, Popover } from '@mui/material'
import { Flag } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'

type StatusPickerProps = {
  status: string
  updateStatus: (status: string) => void
}

const StatusPicker = (props: StatusPickerProps) => {
  const { status, updateStatus } = props
  const [curStatus, setCurStatus] = useState(status)
  const [statusMenuAnchor, setStatusMenuAnchor] = useState<null | HTMLElement>(
    null
  )

  useEffect(() => {
    setCurStatus(status)
  }, [status])

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setStatusMenuAnchor(event.currentTarget)
  }

  const handleCloseStatusMenu = () => {
    setStatusMenuAnchor(null)
  }

  return (
    <>
      <Box
        display="flex"
        pr="8px"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleOpenStatusMenu(e)
        }}
      >
        {curStatus === 'completed' ? (
          <CircleCheckedIcon width="16px" height="16px" />
        ) : (
          <>
            {curStatus === 'flagged' ? (
              <Flag sx={{ width: '16px', height: '16px' }} color="moneyGreen" />
            ) : (
              <>
                {curStatus === 'cancelled' ? (
                  <CloseIcon
                    sx={{ width: '16px', height: '16px' }}
                    color="error"
                  />
                ) : (
                  <CircleUncheckedIcon width="16px" height="16px" />
                )}
              </>
            )}
          </>
        )}
      </Box>
      <Popover
        open={Boolean(statusMenuAnchor)}
        onClose={handleCloseStatusMenu}
        anchorEl={statusMenuAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box display="flex" flexDirection="column" py="8px">
          <Box
            px="8px"
            py="4px"
            sx={{
              backgroundColor:
                curStatus === 'in_progress' ? 'green1.main' : 'transparent',
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setCurStatus('in_progress')
                updateStatus('in_progress')
                handleCloseStatusMenu()
              }}
            >
              <CircleUncheckedIcon width="24px" height="24px" />
            </IconButton>
          </Box>
          <Box
            px="8px"
            py="4px"
            sx={{
              backgroundColor:
                curStatus === 'flagged' ? 'green1.main' : 'transparent',
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setCurStatus('flagged')
                updateStatus('flagged')
                handleCloseStatusMenu()
              }}
            >
              <Flag
                sx={{
                  width: '24px',
                  height: '24px',
                }}
                color="moneyGreen"
              />
            </IconButton>
          </Box>
          <Box
            px="8px"
            py="4px"
            sx={{
              backgroundColor:
                curStatus === 'completed' ? 'green1.main' : 'transparent',
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setCurStatus('completed')
                updateStatus('completed')
                handleCloseStatusMenu()
              }}
            >
              <CircleCheckedIcon width="24px" height="24px" />
            </IconButton>
          </Box>
          <Box
            px="8px"
            py="4px"
            sx={{
              backgroundColor:
                curStatus === 'cancelled' ? 'green1.main' : 'transparent',
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setCurStatus('cancelled')
                updateStatus('cancelled')
                handleCloseStatusMenu()
              }}
            >
              <CloseIcon width="24px" height="24px" />
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </>
  )
}

export default StatusPicker
