export const projectTypeOptions = [
  {
    label: 'Inside Maps',
    value: 'inside_maps',
  },
  {
    label: 'Manual',
    value: 'manual',
  },
]

// Props that are used by the useCreateSowProjectModule hook
export type CreateSowProjectModuleProps = {
  parentProjectUuid?: string
}

// Props that are used by the presentational components (CreateSowProjectModuleDesktop.tsx, CreateSowProjectModuleMobile.tsx, CreateSowProjectModuleTablet.tsx)
export type CreateSowProjectModulePresentationalProps =
  CreateSowProjectModuleProps & {
    projectType: string
    inspectionDate: Date | null | undefined
    addressID: string
    projectID: string
    costbook: string
    costbookOptions: {
      label: string
      value: string
    }[]
    scope: string
    scopeOptions: {
      label: string
      value: string
    }[]

    loading: boolean

    setProjectType: (value: string) => void
    setInspectionDate: (value: Date | null | undefined) => void
    setAddressID: (value: string) => void
    setProjectID: (value: string) => void
    setCostbook: (value: string) => void
    setScope: (value: string) => void

    onSubmit: (values: any) => void
  }
