import React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  ApolloLink,
} from '@apollo/client'
import { useFirebaseAuth, parseSeverityFromGqlErrors } from '@flock/utils'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import * as Sentry from '@sentry/gatsby'
// @ts-ignore
import { Severity } from '@flock/utils/src/__generated__/protobuf/ts/errors/errors'

type ConfiguredApolloProviderProps = {
  children: React.ReactNode
}
const ConfiguredApolloProvider = ({
  children,
}: ConfiguredApolloProviderProps) => {
  const apolloLinks = []

  const { user } = useFirebaseAuth()

  const errorLink = onError((errResult) => {
    const { operation, graphQLErrors, networkError } = errResult
    const context = operation.getContext()
    const traceId = context.response?.headers?.get('X-Amzn-Trace-Id')
    const graphQLErrorsString = JSON.stringify(graphQLErrors, null, 2)
    const severity = parseSeverityFromGqlErrors(graphQLErrors)
    if (severity !== Severity.SEVERITY_IGNORE) {
      if (
        !(
          operation.operationName.includes('GetValuationV1') &&
          graphQLErrorsString?.includes('record not found')
        )
      ) {
        const error = new Error(
          `GQL Operation failed: ${operation.operationName}`
        )
        Sentry.captureException(error, {
          fingerprint: ['gql-error', operation.operationName, error.message],
          tags: {
            traceId,
            operationName: operation.operationName,
          },
          extra: {
            variables: JSON.stringify(operation.variables, null, 2),
            graphQLErrors: graphQLErrorsString,
            networkError: JSON.stringify(networkError, null, 2),
          },
        })
      }
    }
  })

  apolloLinks.push(errorLink)

  const authorizationLink = setContext(async (_, previousContext) => {
    // If this token will expire in less than an hour, refresh the token
    const token = await user?.getIdToken()
    return {
      headers: {
        ...previousContext.headers,
        Authorization: `Bearer ${token}`,
      },
    }
  })
  apolloLinks.push(authorizationLink)

  const uploadLink = createUploadLink({ uri: process.env.GATSBY_APOLLO_URL })
  apolloLinks.push(uploadLink)

  const client = new ApolloClient({
    link: from(apolloLinks as ApolloLink[]),
    cache: new InMemoryCache(),
  })

  return (
    <>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </>
  )
}

export default ConfiguredApolloProvider
