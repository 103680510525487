import React from 'react'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'
import {
  AdminGetPropertyOccupancyDataDocument,
  AdminUpdateBadDebtDateDocument,
  Core_PropertyDelinquency,
  Core_UpdateBadDebtDateRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'
import { cleanTimeFromDatetime } from '../../utils'

type UpdateBadDebtDateModalParams = {
  delinquency: Core_PropertyDelinquency
  isOpen: boolean
  close: () => void
}

type UpdateBadDebtDateParams = {
  badDebtDate: string
}

const UpdateBadDebtDateModal = (props: UpdateBadDebtDateModalParams) => {
  const { delinquency, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [updateBadDebtDate, { loading }] = useMutation(
    AdminUpdateBadDebtDateDocument
  )

  const onSubmit = async (updateBadDebtDateParams: UpdateBadDebtDateParams) => {
    const { badDebtDate } = updateBadDebtDateParams
    const badDebtDateInput = badDebtDate
      ? cleanTimeFromDatetime(badDebtDate)
      : null

    const updateBadDebtDateInput: Core_UpdateBadDebtDateRequestInput = {
      delinquencyUuid: delinquency.uuid,
      badDebtDate: badDebtDateInput,
    }

    try {
      await updateBadDebtDate({
        variables: {
          input: updateBadDebtDateInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify('Successfully updated delinquency.', 'success')
      handleClose()
    } catch (e) {
      notify('Failed to update delinquency.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Update Bad Debt Date"
      inputConfigs={[
        {
          inputName: 'badDebtDate',
          inputType: InputType.DatePicker,
          renderOn: 'true',
          props: {
            label: 'BadDebtDate',
            fieldProps: {
              fullWidth: true,
            },
            defaultValue: delinquency.badDebtDate,
          },
        },
      ]}
    />
  )
}

export default UpdateBadDebtDateModal
