// Valuation utilities that are shared between multi family and single family valuations.

import {
  Core_MarketRentCompInput,
  Core_OfferValuationCompInput,
  Core_ValuationCategory,
} from '@flock/flock-gql-server/src/__generated__/graphql'

export const supervisorUuids = [
  '88bbdfd2-6644-4ef7-8424-99e41f7b76dc', // Peter
  '8c1547cd-4831-4e27-8f1b-3a24d6d465de', // Ryan
  'f602fe20-bcb8-4137-b528-45964e5326ef', // Jesse Staging
  'fb86cac3-1fa8-4fc4-969e-59754f377959', // Chad
  '12aeb70c-4fab-401c-985b-a121dbd115f8', // e2e test admin account
  'b8ac2c6f-150c-42ff-b1b9-2493aa6fd75f', // Zach
]

export const finalSubmitUuids = [
  '8c1547cd-4831-4e27-8f1b-3a24d6d465de', // Ryan
  'f602fe20-bcb8-4137-b528-45964e5326ef', // Jesse Staging
  'fb86cac3-1fa8-4fc4-969e-59754f377959', // Chad
  '12aeb70c-4fab-401c-985b-a121dbd115f8', // e2e test admin account
  'b8ac2c6f-150c-42ff-b1b9-2493aa6fd75f', // Zach
]

// lead attribution is in lead answers in the format LEAD_ATTRIBUTION_INTERNET_SEARCH
// or something similar. We should remove LEAD_ATTRIBUTION_ and then replace underscores and normalize the case
export const formatAttribution = (attribution: string | undefined) => {
  if (!attribution) {
    return attribution
  }
  return attribution
    .replace('LEAD_ATTRIBUTION_', '')
    .replace(/_/g, ' ')
    .toLowerCase()
}

export const getHoldPeriod = (customerCashOnCashNetYield: number): string => {
  if (customerCashOnCashNetYield < 0.02) {
    return 'Too low CoC Yield'
  } else if (customerCashOnCashNetYield < 0.03) {
    return '7 Years'
  } else if (customerCashOnCashNetYield < 0.04) {
    return '6 Years'
  } else if (customerCashOnCashNetYield < 0.05) {
    return '5 Years'
  } else if (customerCashOnCashNetYield < 0.06) {
    return '4 Years'
  } else {
    return '3 Years'
  }
}

const isOfferCompsInputValid = (
  valuationCategory: Core_ValuationCategory | undefined | null,
  comps: Core_OfferValuationCompInput[] | undefined | null,
  setOfferCompsErrorMessage: (_: string) => void,
  useCma: boolean
): boolean => {
  setOfferCompsErrorMessage('')
  if (!comps) {
    return true
  }
  // if it contains one of the fields (non-dropdown) but no address
  for (let i = 0; i < comps.length; i += 1) {
    const comp = comps[i]
    if (
      !!comp.avmPrice ||
      !!comp.baths ||
      !!comp.beds ||
      !!comp.halfBaths ||
      !!comp.notes ||
      !!comp.selected ||
      !!comp.similarityScore ||
      !!comp.soldDate ||
      !!comp.squareFootage ||
      !!comp.url ||
      !!comp.yearBuild
    ) {
      if (!comp.address) {
        setOfferCompsErrorMessage(
          'An offer comp with inputs in other fields is missing an address'
        )
        return false
      }
    }
  }
  const selectedComps = comps.filter((comp) => comp.selected)
  // make sure selectec comps have all required fields (selected comps same requirements as cma)
  for (let i = 0; i < selectedComps.length; i += 1) {
    const comp = selectedComps[i]
    if (
      !comp.avmPrice ||
      !comp.similarityScore ||
      !comp.squareFootage ||
      !comp.conditionScore ||
      !comp.yearBuild ||
      !comp.soldDate ||
      (!comp.numUnits &&
        valuationCategory ===
          Core_ValuationCategory.ValuationCategoryMultiFamily)
    ) {
      setOfferCompsErrorMessage(
        `Some selected offer comps are missing required fields (sqft, avm, ${
          valuationCategory ===
          Core_ValuationCategory.ValuationCategoryMultiFamily
            ? 'num units, '
            : ''
        } similarity score, condition score, year built, sold date).`
      )
      return false
    }
  }

  // usecma validation
  if (useCma) {
    if (selectedComps.length < 3) {
      setOfferCompsErrorMessage('You must select at least 3 offer comps when using CMA.')
      return false
    }
  }
  return true
}

const isRentCompsInputValid = (
  comps: Core_MarketRentCompInput[] | undefined | null,
  setRentCompsErrorMessage: (_: string) => void
): boolean => {
  setRentCompsErrorMessage('')
  if (!comps) {
    return true
  }
  for (let i = 0; i < comps.length; i += 1) {
    const comp = comps[i]
    // if it contains one of the fields but not the address, then flag
    if (
      !!comp.marketRent ||
      !!comp.notes ||
      !!comp.rentalDate ||
      !!comp.selected ||
      !!comp.squareFootage ||
      !!comp.url ||
      !!comp.selected
    ) {
      if (!comp.address) {
        setRentCompsErrorMessage(
          'A rent comp with inputs in other fields is missing an address'
        )
        return false
      }
    }
  }
  const selectedComps = comps.filter((comp) => comp.selected)
  // validate fields on selected comps
  for (let i = 0; i < selectedComps.length; i += 1) {
    const comp = selectedComps[i]
    if (!comp.marketRent || !comp.squareFootage || !comp.source) {
      setRentCompsErrorMessage(
        'Some selected rent comps are missing required fields (rent, sqft, source)'
      )
      return false
    }
  }

  return true
}

export const isComputeValuationRequestInputValid = (
  valuationCategory: Core_ValuationCategory | undefined | null,
  rentalComps: Core_MarketRentCompInput[] | undefined | null,
  offerComps: Core_OfferValuationCompInput[] | undefined | null,
  setOfferCompsErrorMessage: (_: string) => void,
  setRentCompsErrorMessage: (_: string) => void,
  useCma: boolean
): boolean => {
  const rentCompsValid = isRentCompsInputValid(
    rentalComps,
    setRentCompsErrorMessage
  )
  const offerCompsValid = isOfferCompsInputValid(
    valuationCategory,
    offerComps,
    setOfferCompsErrorMessage,
    useCma
  )
  return rentCompsValid && offerCompsValid
}
