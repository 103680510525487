import React from 'react'
import { Modal, Paper } from '@mui/material'
import { GridForm } from '@flock/shared-ui'
import { Button, Typography } from '@flock/flock-component-library'
import useComputeCashFlowModal from './useComputeCashFlowModal'
import { ComputeCashFlowModalProps } from './computeCashFlowModalTypes'

const ComputeCashFlowModal = (props: ComputeCashFlowModalProps) => {
  const { open, onClose } = props
  const {
    computeCashFlowInputConfigs,
    onSubmit,
    cashNeedsDollars,
    cashNeedsPercent,
    deltaToRedline,
    loading,
    cashOnCashYield,
  } = useComputeCashFlowModal(props)

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '80px',
          left: '50%',
          transform: 'translate(-50%, 0)',
          width: '560px',
          maxHeight: '75vh',
          overflowY: 'scroll',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <GridForm
          inputConfigs={computeCashFlowInputConfigs}
          onSubmit={onSubmit}
          loading={loading}
          ctaText="Compute"
          gridProps={{
            spacing: 2,
          }}
          ctaButtonProps={{ sx: { width: '140px' } }}
        />
        <Typography>Cash Needs: ${cashNeedsDollars.toFixed(0)}</Typography>
        <Typography>
          Cash Needs: {(cashNeedsPercent * 100).toFixed(1)}%
        </Typography>
        <Typography>
          Delta to 50% Redline: ${deltaToRedline.toFixed(0)}
        </Typography>
        <Typography>
          Computed Cash on Cash Yield: {(cashOnCashYield * 100).toFixed(2)}%
        </Typography>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{ width: '140px', alignSelf: 'flex-end', marginTop: '16px' }}
        >
          Close
        </Button>
      </Paper>
    </Modal>
  )
}

export default ComputeCashFlowModal
