import { gql, useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useSnackbar } from '@flock/shared-ui'
import { AdminAddressValuationsFetchRentalCompsDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { FetchRentalCompsModalProps } from './fetchRentalCompsModalTypes'

export const FETCH_RENTAL_COMPS = gql`
  mutation AdminAddressValuationsFetchRentalComps(
    $input: Core_FetchRentalCompsRequestInput!
  ) {
    fetchRentalComps(input: $input) {
      comps {
        source
        marketRent
        url
        address
        notes
        squareFootage
        rentalDate
        uuid
        addressUuid
        selected
        updatedAt
        distance
        addressObj {
          city
          county
          latitude
          longitude
          state
          street
          streetNumber
          unit
          zipcode
        }
      }
    }
  }
`

const useFetchRentalCompsModal = (props: FetchRentalCompsModalProps) => {
  const { address, onClose, parseRentalCompsForPrefill } = props

  const { notify } = useSnackbar()

  const [fetchRentalComps, { data: comps, loading }] = useMutation(
    AdminAddressValuationsFetchRentalCompsDocument
  )

  useEffect(() => {
    parseRentalCompsForPrefill(comps?.fetchRentalComps?.comps)
  }, [comps, parseRentalCompsForPrefill])

  const submitFetchRentalComps = async () => {
    try {
      await fetchRentalComps({
        variables: {
          input: {
            formattedAddress: address,
          },
        },
      })
      notify('Rental comps successfully fetched', 'success')
      onClose()
    } catch (e) {
      notify('Failed to fetch rental comps, please try again', 'error')
    }
  }

  return {
    submitFetchRentalComps,
    loading,
  }
}

export default useFetchRentalCompsModal
