import { useMutation } from '@apollo/client'
import { AdminUpdateScopeOfWorkItemDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import { useEffect, useMemo, useState } from 'react'
import { useSowSaveContext } from '../SowSaveContextProvider'
import {
  DeficiencyRowProps,
  DeficiencyRowPresentationalProps,
} from './deficiencyRowTypes'

const useDeficiencyRow: (
  props: DeficiencyRowProps
) => DeficiencyRowPresentationalProps = (props: DeficiencyRowProps) => {
  const {
    uuid,
    expandedAll,
    deficiencyCatalog,
    deficiency,
    solutions,
    costCatalog,
  } = props
  const [expanded, setExpanded] = useState(false)
  const [hasFlaggedSolution, setHasFlaggedSolution] = useState(false)
  const { notify } = useSnackbar()
  const { onSaveSuccess, onSaveError, setSaveLoading, refetchProject } =
    useSowSaveContext()

  const [updateSOWItem] = useMutation(AdminUpdateScopeOfWorkItemDocument)
  const updateDeficiency = async (status: string) => {
    setSaveLoading(true)

    try {
      await updateSOWItem({
        variables: {
          input: {
            uuid,
            itemStatus: status,
          },
        },
      })
      onSaveSuccess()
      await refetchProject()
    } catch (e) {
      notify('Failed to update deficiency', 'error')
      onSaveError(`Failed to update deficiency: ${e.message}`)
    }
  }

  const solutionOptions = useMemo(() => {
    if (!deficiency || !deficiencyCatalog[deficiency]) return []
    return deficiencyCatalog[deficiency].map((item) => item.solution)
  }, [deficiency, deficiencyCatalog])

  useEffect(() => {
    setExpanded(expandedAll)
  }, [expandedAll])

  useEffect(() => {
    // Check if any solutions do not match the costbook
    const hasFlag = solutions.find((solution) => {
      // If no item code, this is a custom solution and should be flagged
      if (!solution.itemCode) return true

      const curCategory = solution.category
      // If there's no category on the solution, it should be flagged
      if (!curCategory || !costCatalog[curCategory]) {
        return true
      }

      // If the solution is not in the costbook, it should be flagged
      const matchingItem = costCatalog[curCategory].find(
        (item) => item.itemCode === solution.itemCode
      )
      if (!matchingItem) return true

      return (
        matchingItem!.solution !== solution.solution ||
        matchingItem!.description !== solution.description ||
        matchingItem!.price !== solution.unitPrice ||
        matchingItem!.uom !== solution.unitOfMeasurement
      )
    })

    setHasFlaggedSolution(!!hasFlag)
  }, [JSON.stringify(solutions)])

  return {
    ...props,
    expanded,
    setExpanded,
    updateDeficiency,
    solutionOptions,
    hasFlaggedSolution,
  }
}

export default useDeficiencyRow
