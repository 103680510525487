import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useCallback, useEffect, useState, useMemo } from 'react'

import {
  AnyInputConfig,
  InputType,
  useSnackbar,
  InputConfig,
} from '@flock/shared-ui'
import { useParams } from '@reach/router'
import { Typography } from '@mui/material'
import {
  AdminAddressValuationsGetMultiFamilyAddressValuationDocument,
  AdminAddressValuationsComputeSalesforceAddressMultiFamilyValuationDocument,
  Core_ValuationType,
  Core_CompSource,
  Core_ComputeSalesforceAddressMultiFamilyValuationRequestInput,
  Core_MultiFamilyValuation,
  Core_Property,
  Core_MarketRentCompInput,
  Core_OfferValuationCompInput,
  Core_MultiFamilyValuationUnitInputInput,
  Core_MultiFamilyValuationPropertyInputInput,
  AdminAddressValuationsGetAddressValuationHistoryDocument,
  Core_ValuationCategory,
  Core_SalesforceVerifiedProperty,
  AdminGetScrapedYearBuiltDocument,
  Core_OfferValuationComp,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { formatIntegerDollars } from '@flock/utils'
import { isEqual } from 'lodash'

import {
  supervisorUuids,
  finalSubmitUuids,
  getCompsValidationErrorMessage,
  getConditionScoreFromCondition,
  GetUiValuationType,
} from '../valuationUtils'
import { MultiFamilyComputeAddressValuationTabProps } from './multiFamilyComputeAddressValuationTabTypes'

export const GET_MULTIFAMILY_VALUATION = gql`
  fragment ValuationTrailFields on Core_ValuationTrailNode {
    name
    description
    value
  }
  query AdminAddressValuationsGetMultiFamilyAddressValuation(
    $input: Core_GetMultiFamilyAddressValuationRequestInput!
  ) {
    getMultiFamilyAddressValuation(input: $input) {
      valuation {
        uuid
        type
        unitInputs {
          remodelCost {
            healthAndSafetyCapex
            immediateAddressableCapex
            notes
            score
            totalAddressableCapex
          }
          unitName
          monthsRemainingOnLease
          currentlyOccupied
          currentRent
          avmProjectedRent
          analystProjectedRent
          undergoingEviction
          beds
          baths
          bathsDouble
          squareFootage
          addressUuid
          rentNotes
          marketRentComps {
            source
            marketRent
            url
            address
            notes
            squareFootage
            rentalDate
            uuid
            addressUuid
            selected
            updatedAt
            distance
          }
          offerPriceComps {
            source
            similarityScore
            url
            avmPrice
            squareFootage
            address
            beds
            baths
            bathsDouble
            halfBaths
            yearBuild
            notes
            estimatedRemodelCost
            soldDate
            uuid
            addressUuid
            selected
            updatedAt
            distance
            conditionScore
            numUnits
            flatAvmAdjustment
          }
          additionalMonthlyCost
          additionalMonthlyCostNotes
          isSectionEight
        }
        propertyInput {
          propertyRemodelCost {
            healthAndSafetyCapex
            immediateAddressableCapex
            notes
            score
            totalAddressableCapex
          }
          otherCosts {
            acquisition
            miscellaneous
            hoa
            propertyTaxes
            inPlacePropertyTaxes
            notes
            hoaExists
            addressNearbyHomeHoa
            urlNearbyHomeHoa
            additionalMonthlyCost
            additionalMonthlyCostNotes
          }
          selfReportedValue
          cashToClose
          mortgageAmount
          cashTakeout
          fiveYearTreasuryRate
          propertyAvmValue
          propertyFlatAvmAdjustment
          offerPriceNotes
        }
        outputs {
          impliedYieldMinimums
          msaLevelMinimums
          finalOfferPrice
          grossYield
          capRate
          netYield
          noiMarginAverage
          pass
          netYieldAdjustedOfferPrice
          submarketRentDeduction
          daysInRemodelDeduction
          grossYieldOnAdjustedOfferPrice
          unconstrainedMsaYieldMin
          unconstrainedMsaYieldMinOfferPrice
          inPlaceNetYield
          uwCashOnCashYield {
            customerUwCashOnCashYield
            currentUwCashOnCashYield
          }
          remodelCosts {
            score
            notes
            immediateAddressableCapex
            totalAddressableCapex
            healthAndSafetyCapex
          }
          insuranceExpense
        }
        rejectionReason
        expiresAt
        finalOfferPrice
        operatorUuid
        salesApproved
        property {
          uuid
          addressUuid
          propertyType
          beds
          baths
          bathsDouble
          halfBaths
          sqft
          yearBuilt
          notes
        }
        valuationTrail {
          ...ValuationTrailFields
          nodes {
            ...ValuationTrailFields
            nodes {
              ...ValuationTrailFields
              nodes {
                ...ValuationTrailFields
                nodes {
                  ...ValuationTrailFields
                  nodes {
                    ...ValuationTrailFields
                    nodes {
                      ...ValuationTrailFields
                      nodes {
                        ...ValuationTrailFields
                        nodes {
                          ...ValuationTrailFields
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        updatedAt
      }
    }
  }
`

export const COMPUTE_MULTIFAMILY_VALUATION = gql`
  fragment ValuationTrailFields on Core_ValuationTrailNode {
    name
    description
    value
  }
  mutation AdminAddressValuationsComputeSalesforceAddressMultiFamilyValuation(
    $input: Core_ComputeSalesforceAddressMultiFamilyValuationRequestInput!
  ) {
    computeSalesforceAddressMultiFamilyValuation(input: $input) {
      valuation {
        uuid
        addressId
        type
        unitInputs {
          remodelCost {
            healthAndSafetyCapex
            immediateAddressableCapex
            notes
            totalAddressableCapex
            score
          }
          unitName
          monthsRemainingOnLease
          currentlyOccupied
          currentRent
          avmProjectedRent
          analystProjectedRent
          undergoingEviction
          beds
          baths
          bathsDouble
          squareFootage
          addressUuid
          rentNotes
          marketRentComps {
            source
            marketRent
            url
            address
            notes
            squareFootage
            rentalDate
            uuid
            addressUuid
            selected
            updatedAt
            distance
          }
          offerPriceComps {
            source
            similarityScore
            url
            avmPrice
            squareFootage
            address
            beds
            baths
            bathsDouble
            halfBaths
            yearBuild
            notes
            estimatedRemodelCost
            soldDate
            uuid
            addressUuid
            selected
            updatedAt
            distance
            conditionScore
            numUnits
          }
          additionalMonthlyCost
          additionalMonthlyCostNotes
        }
        propertyInput {
          propertyRemodelCost {
            healthAndSafetyCapex
            immediateAddressableCapex
            notes
            score
            totalAddressableCapex
          }
          otherCosts {
            acquisition
            miscellaneous
            hoa
            propertyTaxes
            inPlacePropertyTaxes
            notes
            hoaExists
            addressNearbyHomeHoa
            urlNearbyHomeHoa
            additionalMonthlyCost
            additionalMonthlyCostNotes
          }
          selfReportedValue
          cashToClose
          mortgageAmount
          cashTakeout
          fiveYearTreasuryRate
          propertyAvmValue
          propertyFlatAvmAdjustment
          offerPriceNotes
        }
        outputs {
          impliedYieldMinimums
          msaLevelMinimums
          finalOfferPrice
          grossYield
          capRate
          netYield
          noiMarginAverage
          pass
          netYieldAdjustedOfferPrice
          submarketRentDeduction
          grossYieldOnAdjustedOfferPrice
          unconstrainedMsaYieldMin
          unconstrainedMsaYieldMinOfferPrice
          inPlaceNetYield
          uwCashOnCashYield {
            customerUwCashOnCashYield
            currentUwCashOnCashYield
          }
          daysInRemodelDeduction
          remodelCosts {
            healthAndSafetyCapex
            immediateAddressableCapex
            notes
            score
            totalAddressableCapex
          }
        }
        rejectionReason
        expiresAt
        finalOfferPrice
        operatorUuid
        salesApproved
        property {
          yearBuilt
          sqft
        }
        valuationTrail {
          ...ValuationTrailFields
          nodes {
            ...ValuationTrailFields
            nodes {
              ...ValuationTrailFields
              nodes {
                ...ValuationTrailFields
                nodes {
                  ...ValuationTrailFields
                  nodes {
                    ...ValuationTrailFields
                    nodes {
                      ...ValuationTrailFields
                    }
                  }
                }
              }
            }
          }
        }
        updatedAt
      }
    }
  }
`

const useMultiFamilyComputeAddressValuationTab = (
  props: MultiFamilyComputeAddressValuationTabProps
) => {
  const {
    addressData,
    operatorData,
    valuationHistory,
    valuationHistoryLoading,
  } = props
  let { addressId } = useParams()
  addressId = addressId as string
  const { notify } = useSnackbar()
  const [loading, setLoading] = useState(true)
  const [computedValuationResult, setComputedValuationResult] =
    useState<Core_MultiFamilyValuation>()
  // we want to use 1 month away - rounded up -> so we add 2 months and use the month value
  const contributionMonth = useMemo(() => {
    const tempMonth = new Date(new Date().setMonth(new Date().getMonth() + 2))
    tempMonth.setDate(1)
    return tempMonth
  }, [])

  const [valuationHistoryModalOpen, setValuationHistoryModalOpen] =
    useState(false)
  const [rejectValuationModalOpen, setRejectValuationModalOpen] =
    useState(false)
  const [valuationInputs, setValuationInputs] =
    useState<Core_ComputeSalesforceAddressMultiFamilyValuationRequestInput | null>(
      null
    )

  // prefilled comps from the previous valuation
  const [rentalCompsPrefill, setRentalCompsPrefill] = useState<any>({})
  const [offerCompsPrefill, setOfferCompsPrefill] = useState<any>({})
  const [unitInputsPrefill, setUnitInputsPrefill] = useState<any>({})
  const [numPrefillOfferComps, setNumPrefillOfferComps] = useState(3)
  const [numPrefillRentalComps, setNumPrefillRentalComps] = useState(3)
  const [hasSubmittedInitialValuation, setHasSubmittedInitialValuation] =
    useState(false)
  // since we can have multiple units, store array of analystprojectedrent vs housecanary projected rent ratios
  const [
    analystProjectedRentVsHousecanaryProjectedRent,
    setAnalystProjectedRentVsHousecanaryProjectedRent,
  ] = useState<number[]>([])
  const [valuationType, setValuationType] = useState('')

  const [numUnits, setNumUnits] = useState(0)
  const [unitNames, setUnitNames] = useState<string[]>([])

  const [offerCompsErrorMessage, setOfferCompsErrorMessage] = useState('')
  const [rentCompsErrorMessage, setRentCompsErrorMessage] = useState('')

  // map of unit name vs ratio of analyst projected rent vs the average of the rent comps for that unit per sqft.
  const [
    perUnitRentalCompsAverageVsAvmRentMap,
    setPerUnitRentalCompsAverageVsAvmRentMap,
  ] = useState<Map<string, number>>(new Map())

  // gets prefilled fields for the form from salesforce data
  const getPrefilledValuationFieldsFromSalesforce = useCallback(
    (
      address: Core_SalesforceVerifiedProperty | null | undefined,
      prefilledValuationInputs: Core_ComputeSalesforceAddressMultiFamilyValuationRequestInput
    ) => {
      let newPrefilledValuationInputs = { ...prefilledValuationInputs }
      let unitInputs: Core_MultiFamilyValuationUnitInputInput[] = []
      if (address) {
        const units = address.units || []
        unitInputs = units.map(
          (unit) =>
            ({
              unitName: unit?.unit || '',
              currentlyOccupied: false,
              undergoingEviction: false,
              beds: unit?.beds,
              baths: Math.floor(unit?.baths || 0),
              bathsDouble: unit?.baths,
              squareFootage: unit?.sqft,
              remodelCost: {
                // if unit property condition is not filled out, we default to the value of the address itself
                score: getConditionScoreFromCondition(
                  unit?.propertyCondition || address?.propertyCondition
                ),
              },
            } as Core_MultiFamilyValuationUnitInputInput)
        )
      }

      newPrefilledValuationInputs = {
        ...newPrefilledValuationInputs,
        unitInputs,
        property: {
          ...newPrefilledValuationInputs.property,
          propertyType: address?.propertyType,
          beds: address?.beds,
          baths: Math.floor(address?.baths || 0),
          bathsDouble: address?.baths,
          yearBuilt: address?.yearBuilt,
        },
      }
      return newPrefilledValuationInputs
    },
    []
  )

  const parseExistingRentalComps = (
    unitInputs: Core_MultiFamilyValuationUnitInputInput[] | null | undefined
  ) => {
    if (!unitInputs) {
      return
    }
    const comps: any[] = []
    unitInputs.forEach((unitInput) => {
      if (unitInput.marketRentComps) {
        unitInput.marketRentComps.forEach((comp) => {
          // find the comp with the same address in the list
          // problem is that key uses idx so we just need to iterate through all of the values.
          const existingComp = comps.find((comp2) =>
            Array.from(Object.values(comp2)).some((v) => v === comp.address)
          )
          if (existingComp) {
            const idx = comps.indexOf(existingComp)
            const prefix = unitInput.unitName
            comps[idx][`${prefix}RentalCompSelected${idx}`] = true
          } else {
            const idx = comps.length
            const prefix = unitInput.unitName
            const newComp = {
              [`rentCompSource${idx}`]: comp.source,
              [`rentCompMarketRent${idx}`]: comp.marketRent,
              [`rentCompRentalDate${idx}`]: comp.rentalDate,
              [`rentCompSquareFootage${idx}`]: comp.squareFootage,
              [`rentCompUrl${idx}`]: comp.url,
              [`rentCompAddressMulti${idx}`]: comp.address,
              [`rentCompNotes${idx}`]: comp.notes,
              [`rentCompDistance${idx}`]: comp.distance,
              [`${prefix}RentalCompSelected${idx}`]: true,
            }
            comps.push(newComp)
          }
        })
      }
    })
    setNumPrefillRentalComps(comps.length)
    const prefill = Object.assign({}, ...comps)
    setRentalCompsPrefill(prefill)
  }

  const parseUnitInputs = (
    unitInputs: Core_MultiFamilyValuationUnitInputInput[] | null | undefined
  ) => {
    if (!unitInputs) {
      return
    }
    const prefill = Object.assign(
      {},
      ...(unitInputs.map((unitInput) => ({
        [`${unitInput.unitName}Beds`]: unitInput?.beds,
        [`${unitInput.unitName}Baths`]:
          unitInput?.bathsDouble || unitInput?.baths,
        [`${unitInput.unitName}Sqft`]: unitInput?.squareFootage,
        [`${unitInput.unitName}CurrentRent`]: unitInput?.currentRent,
        [`${unitInput.unitName}AnalystProjectedRent`]:
          unitInput?.analystProjectedRent,
        [`${unitInput.unitName}AvmProjectedRent`]: unitInput?.avmProjectedRent,
        [`${unitInput.unitName}MonthsRemainingOnLease`]:
          unitInput?.monthsRemainingOnLease,
        [`${unitInput.unitName}CurrentlyOccupied`]: unitInput?.currentlyOccupied
          ? 'yes'
          : 'no',
        [`${unitInput.unitName}UndergoingEviction`]:
          unitInput?.undergoingEviction,
        [`${unitInput.unitName}RentNotes`]: unitInput?.rentNotes,
        [`${unitInput.unitName}AdditionalMonthlyCost`]:
          unitInput?.additionalMonthlyCost,
        [`${unitInput.unitName}AdditionalMonthlyCostNotes`]:
          unitInput?.additionalMonthlyCostNotes,
        [`${unitInput.unitName}IsSectionEight`]: unitInput?.isSectionEight
          ? 'yes'
          : 'no',
        [`${unitInput.unitName}HealthAndSafetyCapex`]:
          unitInput?.remodelCost?.healthAndSafetyCapex,
        [`${unitInput.unitName}ImmediateAddressableCapex`]:
          (unitInput?.remodelCost?.immediateAddressableCapex || 0) -
          (unitInput?.remodelCost?.healthAndSafetyCapex || 0),
        [`${unitInput.unitName}TotalAddressableCapex`]:
          (unitInput?.remodelCost?.totalAddressableCapex || 0) -
          (unitInput?.remodelCost?.immediateAddressableCapex || 0),
        [`${unitInput.unitName}RemodelCostScore`]:
          unitInput?.remodelCost?.score,
      })) || [])
    )
    setUnitInputsPrefill(prefill)
  }

  const parseExistingOfferComps = (
    unitInputs: Core_MultiFamilyValuationUnitInputInput[] | null | undefined
  ) => {
    if (!unitInputs) {
      return
    }
    const comps: any[] = []
    unitInputs.forEach((unitInput) => {
      if (unitInput.offerPriceComps) {
        unitInput.offerPriceComps.forEach((comp) => {
          // find the comp with the same address in the list
          const existingComp = comps.find((comp2) =>
            Array.from(Object.values(comp2)).some((v) => v === comp.address)
          )
          if (existingComp) {
            const idx = comps.indexOf(existingComp)
            const prefix = unitInput.unitName
            comps[idx][`${prefix}OfferCompSelected${idx}`] = true
          } else {
            const idx = comps.length
            const prefix = unitInput.unitName
            const newComp = {
              [`offerCompSource${idx}`]: comp.source,
              [`similarityScore${idx}`]: comp.similarityScore || 0,
              [`offerCompUrl${idx}`]: comp.url,
              [`avmPrice${idx}`]: comp.avmPrice,
              [`squareFootage${idx}`]: comp.squareFootage,
              [`offerCompAddressMulti${idx}`]: comp.address,
              [`beds${idx}`]: comp.beds,
              [`baths${idx}`]: comp.baths,
              [`yearBuilt${idx}`]: comp.yearBuild,
              [`offerCompSoldDate${idx}`]: comp.soldDate,
              [`offerCompNotes${idx}`]: comp.notes,
              [`conditionScore${idx}`]: comp.conditionScore,
              [`numUnits${idx}`]: comp.numUnits,
              [`offerCompDistance${idx}`]: comp.distance,
              [`${prefix}OfferCompSelected${idx}`]: true,
              [`flatAvmAdjustment${idx}`]: comp.flatAvmAdjustment,
            }
            comps.push(newComp)
          }
        })
      }
    })
    setNumPrefillOfferComps(comps.length)
    const prefill = Object.assign({}, ...comps)
    setOfferCompsPrefill(prefill)
  }

  const { data: multiFamilyValuation } = useQuery(
    AdminAddressValuationsGetMultiFamilyAddressValuationDocument,
    {
      fetchPolicy: 'no-cache', // necessary to cause refetch when switching tabs.
      variables: {
        input: {
          addressId,
        },
      },
      onError: () => {
        let prefilledValuationInputs = {
          ...valuationInputs,
        } as Core_ComputeSalesforceAddressMultiFamilyValuationRequestInput
        prefilledValuationInputs = getPrefilledValuationFieldsFromSalesforce(
          addressData?.salesforceAddress?.address,
          prefilledValuationInputs
        )
        setValuationInputs({
          ...prefilledValuationInputs,
        })
        parseUnitInputs(prefilledValuationInputs.unitInputs)
        setLoading(false)
      },
      onCompleted: async () => {
        const previousValuation: Core_MultiFamilyValuation =
          multiFamilyValuation?.getMultiFamilyAddressValuation
            ?.valuation as Core_MultiFamilyValuation
        let prefilledValuationInputs = {
          ...valuationInputs,
        } as Core_ComputeSalesforceAddressMultiFamilyValuationRequestInput

        prefilledValuationInputs = getPrefilledValuationFieldsFromSalesforce(
          addressData?.salesforceAddress?.address,
          prefilledValuationInputs
        )

        if (previousValuation) {
          prefilledValuationInputs = {
            ...prefilledValuationInputs,
            propertyInput: previousValuation.propertyInput,
            unitInputs: previousValuation.unitInputs,
            valuationType: previousValuation.type,
            property: previousValuation.property,
          }

          if (previousValuation.finalOfferPrice === 0) {
            prefilledValuationInputs.valuationUuid = previousValuation.uuid
            prefilledValuationInputs.valuationType = GetUiValuationType(
              previousValuation.type
            )
            setComputedValuationResult(previousValuation)
          } else if (
            previousValuation.finalOfferPrice !==
            previousValuation.outputs?.finalOfferPrice
          ) {
            notify('Submitted offer price differs from latest run', 'warning')
          }

          parseExistingOfferComps(previousValuation.unitInputs)
          parseExistingRentalComps(previousValuation.unitInputs)
          parseUnitInputs(previousValuation.unitInputs)
          setValuationType(GetUiValuationType(previousValuation.type))
        }
        setValuationInputs(prefilledValuationInputs)

        setLoading(false)
      },
      skip: !addressData,
    }
  )

  const [computeValuation, { loading: computeValuationLoading }] = useMutation(
    AdminAddressValuationsComputeSalesforceAddressMultiFamilyValuationDocument,
    {
      fetchPolicy: 'no-cache',
      onError: () => {
        notify(
          'An error occurred while computing the valuation. Please try again.',
          'error'
        )
      },
      onCompleted: () => {
        notify('Valuation successfully computed', 'success')
      },
      refetchQueries: [
        AdminAddressValuationsGetMultiFamilyAddressValuationDocument,
        AdminAddressValuationsGetAddressValuationHistoryDocument,
      ],
    }
  )

  const computeValuationFormFormProps = useMemo(
    () => ({
      defaultValues: {
        valuationType:
          valuationInputs?.valuationType ||
          Core_ValuationType.ValuationTypeInitial,
        ...rentalCompsPrefill,
        ...offerCompsPrefill,
        ...unitInputsPrefill,
      },
    }),
    [valuationInputs, rentalCompsPrefill, offerCompsPrefill, unitInputsPrefill]
  )

  useEffect(() => {
    if (valuationHistoryLoading) return
    const multiFamilyValuations =
      valuationHistory?.getAddressValuationHistory?.multiFamilyValuations
    if (multiFamilyValuations) {
      const existsInitialSubmittedValuation = multiFamilyValuations.some(
        (valuation) =>
          valuation?.finalOfferPrice && valuation.finalOfferPrice > 0
      )
      if (existsInitialSubmittedValuation) {
        setHasSubmittedInitialValuation(true)
      }
    }
  }, [valuationHistory, valuationHistoryLoading])

  useEffect(() => {
    const units = addressData?.salesforceAddress?.address?.units || []
    setNumUnits(units.length)
    setUnitNames(units.map((unit) => unit?.unit || '')) // for typing purposes only
  }, [addressData])

  const address = addressData?.salesforceAddress?.address

  const operatorUuid = operatorData?.operator?.operator?.uuid || ''
  const formattedAddress =
    addressData?.salesforceAddress?.address?.formattedAddress || ''

  const { data: scrapedYearBuiltData } = useQuery(
    AdminGetScrapedYearBuiltDocument,
    {
      variables: {
        input: {
          formattedAddress,
        },
      },
      skip: !formattedAddress,
    }
  )

  const getSupervisorReviewRequiredMessage = (): string => {
    if (computedValuationResult?.outputs?.finalOfferPrice! > 600000) {
      return 'Final Offer price is greater than 600k.'
    }
    if (
      analystProjectedRentVsHousecanaryProjectedRent.some(
        (ratio: number) => ratio < 0.9 || ratio > 1.1
      )
    ) {
      return "A unit's Analyst Projected Rent is not within 10% of the HouseCanary Projected Rent."
    }
    const unitsWithAvmCompRentPerSqftRatiosOutsideRange: string[] = []
    Array.from(perUnitRentalCompsAverageVsAvmRentMap.entries()).forEach(
      ([unitName, ratio]) => {
        if (ratio < 0.9 || ratio > 1.1) {
          unitsWithAvmCompRentPerSqftRatiosOutsideRange.push(unitName)
        }
      }
    )
    if (unitsWithAvmCompRentPerSqftRatiosOutsideRange.length > 0) {
      const units = unitsWithAvmCompRentPerSqftRatiosOutsideRange.join(', ')
      return `Units: ${units} Analyst Projected Rent per sqft is not within 10% of the average of the Rent Comps for that unit.`
    }
    if (
      computedValuationResult?.unitInputs.some(
        (unitInput) => unitInput.marketRentComps.length !== 3
      )
    ) {
      return 'Some units do not have 3 market rent comps.'
    }
    return ''
  }

  const canSubmitValuation = () => {
    const supervisorRequiredReason = getSupervisorReviewRequiredMessage()
    if (supervisorRequiredReason) {
      if (!supervisorUuids.includes(operatorUuid as string)) {
        notify(
          `Supervisor must review and submit this valuation. ${supervisorRequiredReason}`
        )
        return false
      }
    }
    if (valuationType === Core_ValuationType.ValuationTypeFinal) {
      if (!finalSubmitUuids.includes(operatorUuid as string)) {
        notify('Only supervisor can submit final valuation')
        return false
      }
    }
    return true
  }

  const canRejectValuation = () => {
    const supervisorRequiredReason = getSupervisorReviewRequiredMessage()
    if (supervisorRequiredReason) {
      if (!supervisorUuids.includes(operatorUuid as string)) {
        notify(
          `Supervisor must review and reject this valuation. ${supervisorRequiredReason}`
        )
        return false
      }
    }
    return true
  }

  // sets any state variables used for live validate state of the form
  const validateFormattedFormData = useCallback(
    (currentFormState: any) => {
      const newAnalystProjectedRentVsHousecanaryProjectedRent =
        currentFormState.offerPriceComps.map((comp: any) => {
          const { analystProjectedRent, housecanaryProjectedRent } = comp
          return analystProjectedRent / housecanaryProjectedRent
        })
      setAnalystProjectedRentVsHousecanaryProjectedRent(
        newAnalystProjectedRentVsHousecanaryProjectedRent
      )

      if (currentFormState.valuationType) {
        setValuationType(currentFormState.valuationType || '')
      }
      // compute per unit analyst projecte rent vs average of comps for that unit.
      const newPerUnitRentalCompsAverageVsAvmRentMap = new Map()
      unitNames.forEach((unitName) => {
        const unitAnalystProjectedRent =
          currentFormState[`${unitName}AnalystProjectedRent`] || 0
        const unitSqft = currentFormState[`${unitName}Sqft`] || 0
        const unitRentPerSqft = unitAnalystProjectedRent / unitSqft

        let cumulativeCompRentPerSqft = 0
        let numComps = 0
        currentFormState.marketRentComps.forEach((comp: any) => {
          if (comp[`${unitName}RentalCompSelected`]) {
            const compRentPerSqft =
              comp.rentCompMarketRent / comp.rentCompSquareFootage
            cumulativeCompRentPerSqft += compRentPerSqft
            numComps += 1
          }
        })
        const averageCompRentPerSqft =
          numComps > 0 ? cumulativeCompRentPerSqft / numComps : 0

        const ratio = unitRentPerSqft / averageCompRentPerSqft
        newPerUnitRentalCompsAverageVsAvmRentMap.set(unitName, ratio)
      })
      // to prevent infinite loop since the input configs depend on the map which would rerender the form
      // and then call this method again. We only want to update the map if it is different.
      if (
        !isEqual(
          newPerUnitRentalCompsAverageVsAvmRentMap,
          perUnitRentalCompsAverageVsAvmRentMap
        )
      ) {
        setPerUnitRentalCompsAverageVsAvmRentMap(
          newPerUnitRentalCompsAverageVsAvmRentMap
        )
      }
    },
    [unitNames, perUnitRentalCompsAverageVsAvmRentMap]
  )

  const generateUnitCheckboxesInputConfigsForOfferComps = useCallback(() => {
    const val: InputConfig<InputType.Checkbox>[] = unitNames.map(
      (unitName) => ({
        name: `${unitName}OfferCompSelected`,
        type: InputType.Checkbox,
        props: {
          label: unitName,
        },
      })
    )
    return val
  }, [unitNames])

  const generateUnitCheckboxesInputConfigsForRentalComps = useCallback(() => {
    const val: InputConfig<InputType.Checkbox>[] = unitNames.map(
      (unitName) => ({
        name: `${unitName}RentalCompSelected`,
        type: InputType.Checkbox,
        props: {
          label: unitName,
        },
      })
    )
    return val
  }, [unitNames])

  const generatePerUnitInputConfigSections = useCallback(() => {
    const val: AnyInputConfig[] = unitNames.map((unitName) => ({
      name: `${unitName}Section`,
      type: InputType.Section,
      gridItemProps: {
        xs: 12,
        spacing: 2,
        pt: '16px',
      },
      props: {
        title: unitName,
        collapsible: true,
        inputConfigs: [
          {
            name: `${unitName}Column1`,
            type: InputType.Section,
            gridItemProps: {
              xs: 3,
            },
            props: {
              inputConfigs: [
                {
                  name: `${unitName}Beds`,
                  type: InputType.Text,
                  props: {
                    size: 'small',
                    label: 'Beds',
                    format: 'number',
                  },
                },
                {
                  name: `${unitName}Baths`,
                  type: InputType.Text,
                  props: {
                    size: 'small',
                    label: 'Baths',
                    format: 'number',
                  },
                },
                {
                  name: `${unitName}Sqft`,
                  type: InputType.Text,
                  props: {
                    size: 'small',
                    label: 'Sqft',
                    format: 'number',
                  },
                },
              ],
            },
          },
          {
            name: `${unitName}Column2`,
            type: InputType.Section,
            gridItemProps: {
              xs: 3,
            },
            props: {
              inputConfigs: [
                {
                  name: `${unitName}AvmProjectedRent`,
                  type: InputType.Text,
                  required: true,
                  props: {
                    size: 'small',
                    label: 'Avm Projected Rent',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: `${unitName}AnalystProjectedRent`,
                  type: InputType.Text,
                  required: true,
                  props: {
                    size: 'small',
                    label: 'Analyst Projected Rent',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: `${unitName}RentNotes`,
                  type: InputType.Text,
                  props: {
                    size: 'small',
                    label: 'Rent Notes',
                    format: 'text',
                  },
                },
                {
                  name: `${unitName}AdditionalMonthlyCostNotes`,
                  type: InputType.Text,
                  props: {
                    size: 'small',
                    label: 'Add. Monthly Cost Notes',
                    format: 'text',
                  },
                },
              ],
            },
          },
          {
            name: `${unitName}Column3`,
            type: InputType.Section,
            gridItemProps: {
              xs: 3,
            },
            props: {
              inputConfigs: [
                {
                  name: `${unitName}RemodelCostScore`,
                  type: InputType.Dropdown,
                  renderIf: (watchedFields: any) =>
                    watchedFields.valuationType !==
                    Core_ValuationType.ValuationTypeFinal,
                  required: true,
                  props: {
                    size: 'small',
                    label:
                      'Remodel Cost Score (Verify with Zillow/HouseCanary photos)',
                    options: [
                      {
                        label: '(5) Pristine',
                        value: 5,
                      },
                      {
                        label: '(4) Great',
                        value: 4,
                      },
                      {
                        label: '(3) Average',
                        value: 3,
                      },
                      {
                        label: '(2) Below Average',
                        value: 2,
                      },
                      {
                        label: '(1) Requires Complete Renovation',
                        value: 1,
                      },
                    ],
                  },
                },
                {
                  name: `${unitName}HealthAndSafetyCapex`,
                  type: InputType.Text,
                  renderIf: (watchedFields: any) =>
                    watchedFields.valuationType ===
                    Core_ValuationType.ValuationTypeFinal,
                  required: true,
                  props: {
                    size: 'small',
                    label: 'Health And Safety Capex',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: `${unitName}ImmediateAddressableCapex`,
                  type: InputType.Text,
                  renderIf: (watchedFields: any) =>
                    watchedFields.valuationType ===
                    Core_ValuationType.ValuationTypeFinal,
                  required: true,
                  props: {
                    size: 'small',
                    label: 'Immediate Capex',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: `${unitName}TotalAddressableCapex`,
                  type: InputType.Text,
                  renderIf: (watchedFields: any) =>
                    watchedFields.valuationType ===
                    Core_ValuationType.ValuationTypeFinal,
                  required: true,
                  props: {
                    size: 'small',
                    label: 'Non Immediate Capex',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: `${unitName}AdditionalMonthlyCost`,
                  type: InputType.Text,
                  props: {
                    size: 'small',
                    label: 'Add. Monthly Cost',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: `${unitName}IsSectionEight`,
                  type: InputType.Dropdown,
                  props: {
                    InputLabelProps: { shrink: true },
                    label: 'Is Section Eight?',
                    size: 'small',
                    options: [
                      {
                        label: 'No',
                        value: 'no',
                      },
                      {
                        label: 'Yes',
                        value: 'yes',
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            name: `${unitName}Column4`,
            type: InputType.Section,
            gridItemProps: {
              xs: 3,
            },
            props: {
              inputConfigs: [
                {
                  name: `${unitName}CurrentlyOccupied`,
                  type: InputType.Dropdown,
                  props: {
                    InputLabelProps: { shrink: true },
                    size: 'small',
                    label: 'Currently Occupied?',
                    options: [
                      {
                        label: 'Yes',
                        value: 'yes',
                      },
                      {
                        label: 'No',
                        value: 'no',
                      },
                    ],
                  },
                },
                {
                  name: `${unitName}UndergoingEviction`,
                  type: InputType.Checkbox,
                  gridItemProps: {
                    sm: 6,
                  },
                  props: {
                    label: 'Undergoing Eviction',
                    size: 'small',
                  },
                },
                {
                  name: `${unitName}CurrentRent`,
                  type: InputType.Text,
                  renderIf: (watchedFields: any) =>
                    watchedFields[`${unitName}CurrentlyOccupied`] === 'yes',
                  required: true,
                  props: {
                    size: 'small',
                    label: 'Current Monthly Rent',
                    format: 'dollars',
                    placeholder: '$',
                  },
                },
                {
                  name: `${unitName}MonthsRemainingOnLease`,
                  type: InputType.Text,
                  renderIf: (watchedFields: any) =>
                    watchedFields[`${unitName}CurrentlyOccupied`] === 'yes',
                  required: true,
                  props: {
                    size: 'small',
                    label: 'Months on Lease Left',
                    format: 'number',
                  },
                },
              ],
            },
          },
        ],
      },
    }))
    return val
  }, [unitNames])

  const computeValuationFormInputConfigs: AnyInputConfig[] = useMemo(
    () => [
      {
        name: 'propertyDetails',
        type: InputType.Section,
        gridItemProps: { xs: 12, pt: '16px' },
        props: {
          title: 'Property Details',
          collapsible: true,
          inputConfigs: [
            {
              name: 'propertyDetailsFirstColumn',
              type: InputType.Section,
              gridItemProps: { xs: 6 },
              props: {
                title: '',
                sectionGridProps: { spacing: 2 },
                inputConfigs: [
                  {
                    name: 'address',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Address: {address?.formattedAddress}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'county',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          County: {address?.addressCounty}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'propertyType',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Property Type:{' '}
                          {valuationInputs?.property?.propertyType}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'beds',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Beds: {valuationInputs?.property?.beds}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'baths',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Baths: {valuationInputs?.property?.bathsDouble}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'hoa',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Annual HOA: ${address?.annualHoa?.toLocaleString()}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'additionalInfo',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Additional Info:{' '}
                          {
                            addressData?.salesforceAddress?.address
                              ?.additionalInfo
                          }
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'selfReportedValue',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Self Reported Value:{' '}
                          {formatIntegerDollars(
                            addressData?.salesforceAddress?.address
                              ?.selfReportedValue || 0
                          )}
                        </Typography>
                      ),
                    },
                  },
                  {
                    name: 'scrapedYearBuilt',
                    type: InputType.CustomComponent,
                    props: {
                      component: (
                        <Typography variant="p2">
                          Scraped Year Built:{' '}
                          {scrapedYearBuiltData?.getScrapedYearBuilt
                            ?.scrapedYearBuilt || ''}
                        </Typography>
                      ),
                    },
                  },
                ],
              },
            },
            {
              name: 'propertyDetailsSecondColumn',
              type: InputType.Section,
              gridItemProps: { xs: 6 },
              props: {
                title: '',
                sectionGridProps: { spacing: 2 },
                inputConfigs: [
                  {
                    name: 'yearBuilt',
                    type: InputType.Text,
                    defaultValue: valuationInputs?.property?.yearBuilt,
                    props: {
                      sx: { width: '112px' },
                      size: 'small',
                      label: 'Year Built',
                      format: 'year',
                    },
                  },
                  {
                    name: 'taxes',
                    type: InputType.Text,
                    required: true,
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts?.propertyTaxes,
                    props: {
                      sx: { width: '160px' },
                      size: 'small',
                      label: 'Property Taxes',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'inPlacePropertyTaxes',
                    type: InputType.Text,
                    required: true,
                    gridItemProps: { xs: 6 },
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts
                        ?.inPlacePropertyTaxes,
                    renderIf: (watchedFields: any) =>
                      watchedFields.valuationType ===
                      Core_ValuationType.ValuationTypeFinal,
                    props: {
                      sx: { width: '160px' },
                      size: 'small',
                      label: 'In Place Property Taxes',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'hoaExists',
                    type: InputType.Dropdown,
                    defaultValue: valuationInputs?.propertyInput?.otherCosts
                      ?.hoaExists
                      ? 'yes'
                      : 'no',
                    props: {
                      InputLabelProps: { shrink: true },
                      sx: { width: '128px' },
                      label: 'Is there an HOA?',
                      size: 'small',
                      options: [
                        {
                          label: 'No',
                          value: 'no',
                        },
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                      ],
                    },
                  },
                  {
                    name: 'yearlyHoaFee',
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.hoaExists === 'yes',
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts?.hoa || 0,
                    props: {
                      sx: { width: '258px' },
                      size: 'small',
                      label: 'HOA Fee (yearly)',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'nearbyHoaAddress',
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.hoaExists === 'yes',
                    defaultValue: `${
                      valuationInputs?.propertyInput?.otherCosts
                        ?.addressNearbyHomeHoa || ''
                    }`,
                    props: {
                      sx: { width: '100%' },
                      size: 'small',
                      label: 'Address of Nearby Home in same HOA',
                      format: 'text',
                    },
                  },
                  {
                    name: 'nearbyHoaUrl',
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.hoaExists === 'yes',
                    defaultValue: `${
                      valuationInputs?.propertyInput?.otherCosts
                        ?.urlNearbyHomeHoa || ''
                    }`,
                    props: {
                      sx: { width: '100%' },
                      size: 'small',
                      label: 'URL of Nearby Home in same HOA',
                      format: 'text',
                    },
                  },
                  {
                    name: 'computationDate',
                    type: InputType.DatePicker,
                    defaultValue: '',
                    props: {
                      size: 'small',
                      label: 'Computation Date',
                      sx: { width: '160px' },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        name: 'genericValuationInputs',
        type: InputType.Section,
        gridItemProps: { xs: 12, pt: '16px' },
        props: {
          title: 'Generic Property Level Inputs',
          collapsible: true,
          inputConfigs: [
            {
              name: 'genericValuationColumn1',
              type: InputType.Section,
              gridItemProps: { xs: 3, spacing: 2 },
              props: {
                title: '',
                inputConfigs: [
                  {
                    name: 'valuationType',
                    type: InputType.Dropdown,
                    required: true,
                    renderIf: () => hasSubmittedInitialValuation,
                    defaultValue: valuationType,
                    props: {
                      size: 'small',
                      InputLabelProps: { shrink: true },
                      label: 'Valuation Type',
                      options: [
                        {
                          label: 'Initial',
                          value: Core_ValuationType.ValuationTypeInitial,
                        },
                        {
                          label: 'Final',
                          value: Core_ValuationType.ValuationTypeFinal,
                        },
                      ],
                    },
                  },
                  {
                    name: 'contributionMonth',
                    type: InputType.DatePicker,
                    defaultValue: contributionMonth,
                    props: {
                      size: 'small',
                      label: `Expected contrib. mo. ${contributionMonth.toLocaleString(
                        'en-US',
                        {
                          month: 'long',
                        }
                      )}`,
                    },
                  },
                  {
                    name: 'propertyAvmValue',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.propertyAvmValue || 0,
                    required: false,
                    props: {
                      size: 'small',
                      label: 'Property AVM Value',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                ],
              },
            },
            {
              name: 'genericValuationColumn2',
              type: InputType.Section,
              gridItemProps: { xs: 3, spacing: 2 },
              props: {
                title: '',
                inputConfigs: [
                  {
                    name: 'miscellaneousCosts',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts?.miscellaneous,
                    props: {
                      size: 'small',
                      label: 'Misc. Costs',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'acquisitionCosts',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts?.acquisition,
                    props: {
                      size: 'small',
                      label: 'Acquisition Costs',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'additionalMonthlyCost',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts
                        ?.additionalMonthlyCost,
                    props: {
                      size: 'small',
                      label: 'Additional Monthly Cost',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'additionalCostNotes',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.otherCosts
                        ?.additionalMonthlyCostNotes,
                    props: {
                      size: 'small',
                      label: 'Additional Cost Notes',
                      format: 'text',
                    },
                  },
                  {
                    name: 'propertyFlatAvmAdjustment',
                    type: InputType.Text,
                    defaultValue:
                      valuationInputs?.propertyInput?.propertyFlatAvmAdjustment,
                    props: {
                      size: 'small',
                      label: 'Property Flat AVM Adjustment',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                ],
              },
            },
            {
              name: 'genericValuationColumn3',
              type: InputType.Section,
              gridItemProps: { xs: 3, spacing: 2 },
              props: {
                title: '',
                inputConfigs: [
                  {
                    name: 'otherCostNotes',
                    type: InputType.Text,
                    defaultValue: `${
                      valuationInputs?.propertyInput?.otherCosts?.notes || ''
                    }`,
                    props: {
                      size: 'small',
                      multiline: true,
                      minRows: 4,
                      maxRows: 4,
                      label: 'Other Cost Notes',
                    },
                  },
                  {
                    name: 'remodelCostNotes',
                    type: InputType.Text,
                    defaultValue: `${
                      valuationInputs?.propertyInput?.propertyRemodelCost
                        ?.notes || ''
                    }`,
                    props: {
                      size: 'small',
                      multiline: true,
                      minRows: 4,
                      maxRows: 4,
                      label: 'Remodel Cost Notes',
                    },
                  },
                  {
                    name: 'offerPriceNotes',
                    type: InputType.Text,
                    defaultValue: `${
                      valuationInputs?.propertyInput?.offerPriceNotes || ''
                    }`,
                    props: {
                      size: 'small',
                      multiline: true,
                      minRows: 4,
                      maxRows: 4,
                      label: 'Offer Price Notes',
                    },
                  },
                ],
              },
            },
            {
              name: 'genericValuationColumn4',
              type: InputType.Section,
              gridItemProps: { xs: 3, spacing: 2 },
              props: {
                title: '',
                inputConfigs: [
                  {
                    name: 'propertyHealthAndSafetyCapex',
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.valuationType ===
                      Core_ValuationType.ValuationTypeFinal,
                    defaultValue: valuationInputs?.propertyInput
                      ?.propertyRemodelCost?.healthAndSafetyCapex
                      ? valuationInputs?.propertyInput?.propertyRemodelCost
                          ?.healthAndSafetyCapex
                      : null,
                    props: {
                      size: 'small',
                      label: 'Health & Safety Capex',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'propertyImmediateAddressableCapex',
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.valuationType ===
                      Core_ValuationType.ValuationTypeFinal,
                    defaultValue:
                      valuationInputs?.propertyInput?.propertyRemodelCost
                        ?.immediateAddressableCapex &&
                      valuationInputs?.propertyInput?.propertyRemodelCost
                        ?.healthAndSafetyCapex
                        ? valuationInputs?.propertyInput?.propertyRemodelCost
                            ?.immediateAddressableCapex -
                          valuationInputs?.propertyInput?.propertyRemodelCost
                            ?.healthAndSafetyCapex
                        : null,
                    props: {
                      size: 'small',
                      label: 'Immed. Addressable Capex',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'propertyTotalAddressableCapex',
                    type: InputType.Text,
                    renderIf: (watchedFields: any) =>
                      watchedFields.valuationType ===
                      Core_ValuationType.ValuationTypeFinal,
                    defaultValue:
                      valuationInputs?.propertyInput?.propertyRemodelCost
                        ?.totalAddressableCapex &&
                      valuationInputs?.propertyInput?.propertyRemodelCost
                        ?.immediateAddressableCapex
                        ? valuationInputs?.propertyInput?.propertyRemodelCost
                            ?.totalAddressableCapex -
                          valuationInputs?.propertyInput?.propertyRemodelCost
                            ?.immediateAddressableCapex
                        : null,
                    props: {
                      size: 'small',
                      label: 'Total Addressable Capex',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      ...generatePerUnitInputConfigSections(),
      {
        name: 'offerPriceCompsSection',
        type: InputType.Section,
        gridItemProps: { xs: 12, pt: '16px' },
        props: {
          title: 'Offer Price Comps',
          collapsible: true,
          inputConfigs: [
            {
              name: 'offerPriceCompsErrorMessage',
              type: InputType.CustomComponent,
              props: {
                component: (
                  <>
                    {offerCompsErrorMessage && (
                      <Typography variant="p2" color="error">
                        {offerCompsErrorMessage}
                      </Typography>
                    )}
                  </>
                ),
              },
            },
            {
              name: 'offerPriceComps',
              type: InputType.Multiform,
              gridItemProps: { sx: { minWidth: '160px' }, spacing: 4 },
              defaultValue: numPrefillOfferComps,
              props: {
                title: 'Offer Price Comp',
                minForms: 3,
                maxForms: numUnits * 3,
                horizontal: true,
                itemContainerProps: { spacing: 4 },
                incrementText: 'Add Offer Comp',
                decrementText: 'Remove Offer Comp',
                inputConfigs: [
                  ...generateUnitCheckboxesInputConfigsForOfferComps(),
                  {
                    name: 'offerCompSource',
                    type: InputType.Dropdown,
                    props: {
                      size: 'small',
                      label: 'Source',
                      InputLabelProps: { shrink: true },
                      options: [
                        {
                          label: 'Housecanary',
                          value: Core_CompSource.CompSourceHousecanary,
                        },
                        {
                          label: 'Zillow',
                          value: Core_CompSource.CompSourceZillow,
                        },
                      ],
                    },
                  },
                  {
                    // since we render both single family and multi family at same time
                    // an issue where the address prefills on the single family form
                    // instead of the multi family form due to name collision
                    // TODO: can change this - after using MUI tabs they don't seem to be rendered at the same time
                    name: 'offerCompAddressMulti',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Address',
                    },
                  },
                  {
                    name: 'zipcode',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Zip Code',
                      format: 'text',
                    },
                  },
                  {
                    name: 'similarityScore0',
                    type: InputType.IndexedComponent,
                    renderIf: () => unitNames.length > 0,
                    props: {
                      components:
                        computedValuationResult?.unitInputs[0]?.offerPriceComps.map(
                          (offerPriceComp: Core_OfferValuationComp) => (
                            <Typography>
                              {unitNames[0]} Similarity Score:{' '}
                              {offerPriceComp?.similarityScore?.toFixed(0)}
                            </Typography>
                          )
                        ),
                    },
                  },
                  {
                    name: 'similarityScore1',
                    type: InputType.IndexedComponent,
                    renderIf: () => unitNames.length > 1,
                    props: {
                      components:
                        computedValuationResult?.unitInputs[1]?.offerPriceComps.map(
                          (offerPriceComp: Core_OfferValuationComp) => (
                            <Typography>
                              {unitNames[1]} Similarity Score:{' '}
                              {offerPriceComp?.similarityScore?.toFixed(0)}
                            </Typography>
                          )
                        ),
                    },
                  },
                  {
                    name: 'similarityScore2',
                    type: InputType.IndexedComponent,
                    renderIf: () => unitNames.length > 2,
                    props: {
                      components:
                        computedValuationResult?.unitInputs[2]?.offerPriceComps.map(
                          (offerPriceComp: Core_OfferValuationComp) => (
                            <Typography>
                              {unitNames[2]} Similarity Score:{' '}
                              {offerPriceComp?.similarityScore?.toFixed(0)}
                            </Typography>
                          )
                        ),
                    },
                  },
                  {
                    name: 'similarityScore3',
                    type: InputType.IndexedComponent,
                    renderIf: () => unitNames.length > 3,
                    props: {
                      components:
                        computedValuationResult?.unitInputs[3]?.offerPriceComps.map(
                          (offerPriceComp: Core_OfferValuationComp) => (
                            <Typography>
                              {unitNames[3]} Similarity Score:{' '}
                              {offerPriceComp?.similarityScore?.toFixed(0)}
                            </Typography>
                          )
                        ),
                    },
                  },
                  {
                    name: 'similarityScore',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Similarity Score Override',
                      format: 'number',
                    },
                  },
                  {
                    name: 'beds',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Beds',
                      format: 'number',
                    },
                  },
                  {
                    name: 'baths',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Baths',
                      format: 'number',
                    },
                  },
                  {
                    name: 'numUnits',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Num Units',
                      format: 'number',
                    },
                  },
                  {
                    name: 'squareFootage',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Square Footage (property)',
                      format: 'number',
                    },
                  },
                  {
                    name: 'offerCompSoldDate',
                    type: InputType.DatePicker,
                    props: {
                      size: 'small',
                      label: 'Sold Date',
                    },
                  },
                  {
                    name: 'avmPrice',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Sold Price (property)',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'yearBuilt',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Year Built',
                      format: 'year',
                    },
                  },
                  {
                    name: 'conditionScore',
                    type: InputType.Dropdown,
                    props: {
                      size: 'small',
                      InputLabelProps: { shrink: true },
                      label: 'Estimated Condition',
                      options: [
                        {
                          label: '(5) Pristine // Well-Maintained',
                          value: 5,
                        },
                        {
                          label: '(4) Great // Well-Maintained',
                          value: 4,
                        },
                        {
                          label: '(3) Average // Worn but adequate',
                          value: 3,
                        },
                        {
                          label: '(2) Below Average // Needs Work',
                          value: 2,
                        },
                        {
                          label:
                            '(1) Requires Complete Renovation // Needs Work',
                          value: 1,
                        },
                      ],
                    },
                  },
                  {
                    name: 'offerCompUrl',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'URL',
                      format: 'text',
                    },
                  },
                  {
                    name: 'offerCompDistance',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Distance (mi)',
                      format: 'number',
                    },
                  },
                  {
                    name: 'flatAvmAdjustment',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Flat AVM Adjustment (property)',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'offerCompNotes',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Notes',
                      multiline: true,
                      maxRows: 3,
                      format: 'text',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        name: 'marketRentCompsSection',
        type: InputType.Section,
        gridItemProps: { xs: 12, pt: '16px' },
        props: {
          title: 'Market Rent Comps',
          collapsible: true,
          inputConfigs: [
            {
              name: 'marketRentCompsErrorMessage',
              type: InputType.CustomComponent,
              props: {
                component: (
                  <>
                    {rentCompsErrorMessage && (
                      <Typography variant="p2" color="error">
                        {rentCompsErrorMessage}
                      </Typography>
                    )}
                  </>
                ),
              },
            },
            {
              name: 'marketRentComps',
              type: InputType.Multiform,
              gridItemProps: { sx: { minWidth: '160px' } },
              defaultValue: numPrefillRentalComps,
              props: {
                title: 'Market Rent Comp',
                minForms: 3,
                maxForms: numUnits * 3,
                horizontal: true,
                itemContainerProps: { spacing: 1 },
                incrementText: 'Add Rental Comp',
                decrementText: 'Remove Rental Comp',
                inputConfigs: [
                  ...generateUnitCheckboxesInputConfigsForRentalComps(),
                  {
                    name: 'rentCompSource',
                    type: InputType.Dropdown,
                    props: {
                      label: 'Source',
                      InputLabelProps: { shrink: true },
                      size: 'small',
                      options: [
                        {
                          label: 'Housecanary',
                          value: Core_CompSource.CompSourceHousecanary,
                        },
                        {
                          label: 'Zillow',
                          value: Core_CompSource.CompSourceZillow,
                        },
                      ],
                    },
                  },
                  {
                    // name collision with single family form
                    // so the address on the single family form will be prefilled instead
                    // without the difference in input name
                    // TODO: can change this - after using MUI tabs they don't seem to be rendered at the same time
                    name: 'rentCompAddressMulti',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Address',
                    },
                  },
                  {
                    name: 'rentCompSquareFootage',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Square Footage',
                      format: 'number',
                    },
                  },
                  {
                    name: 'rentCompRentalDate',
                    type: InputType.DatePicker,
                    props: {
                      size: 'small',
                      label: 'Rental Date',
                    },
                  },
                  {
                    name: 'rentCompMarketRent',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Market Rent',
                      format: 'dollars',
                      placeholder: '$',
                    },
                  },
                  {
                    name: 'rentCompUrl',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'URL',
                      format: 'text',
                    },
                  },
                  {
                    name: 'rentCompDistance',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Distance (mi)',
                      format: 'number',
                    },
                  },
                  {
                    name: 'rentCompNotes',
                    type: InputType.Text,
                    props: {
                      size: 'small',
                      label: 'Notes',
                      multiline: true,
                      maxRows: 3,
                      format: 'text',
                    },
                  },
                ],
              },
            },
            {
              name: 'marketRentCompRatioMessage',
              type: InputType.CustomComponent,
              props: {
                component: (
                  <>
                    {Array.from(
                      perUnitRentalCompsAverageVsAvmRentMap.entries()
                    ).map(([unitName, ratio]) => (
                      <Typography
                        key={unitName}
                        color={Math.abs(1 - ratio) > 0.1 ? 'error' : 'green'}
                      >
                        Per unit avm/sqft to average comp avm/sqft ratio for
                        unit &quot;
                        {unitName}&quot;: {ratio?.toFixed(2)}
                      </Typography>
                    ))}
                  </>
                ),
              },
            },
          ],
        },
      },
    ],
    [
      generatePerUnitInputConfigSections,
      generateUnitCheckboxesInputConfigsForOfferComps,
      generateUnitCheckboxesInputConfigsForRentalComps,
      numUnits,
      contributionMonth,
      valuationInputs,
      valuationType,
      offerCompsPrefill,
      rentalCompsPrefill,
      perUnitRentalCompsAverageVsAvmRentMap,
      offerCompsErrorMessage,
      rentCompsErrorMessage,
      scrapedYearBuiltData,
    ]
  )

  const parseAndSetInputs = (formInputs: any) => {
    // parse property level inputs
    const contributionMonthDate = new Date(formInputs.contributionMonth)
    const contributionMonthMonth = contributionMonthDate.getMonth()
    // best way is prob map of <unitName, array> then for each comp, check if untiNameSelected is true, if so push into the apppropriate array.
    const rentalCompsMap = new Map<string, Core_MarketRentCompInput[]>()
    const offerCompsMap = new Map<string, Core_OfferValuationCompInput[]>()

    const propertyVars = valuationInputs?.property as Core_Property
    const propertyImmediateAddressableCapex =
      (formInputs.propertyImmediateAddressableCapex || 0) +
      (formInputs.propertyHealthAndSafetyCapex || 0)
    const propertyTotalAddressableCapex =
      (formInputs.propertyImmediateAddressableCapex || 0) +
      (formInputs.propertyHealthAndSafetyCapex || 0) +
      (formInputs.propertyTotalAddressableCapex || 0)

    // generate the offer price comps per unit
    // using stored json to prefill so we don't need to set the address uuid on these comps. (the uuid for the comp itself, not the subject property/unit)
    formInputs.offerPriceComps.forEach((offerComp: any) => {
      unitNames.forEach((unitName) => {
        if (offerComp[`${unitName}OfferCompSelected`]) {
          const offerCompInput: Core_OfferValuationCompInput = {
            address: offerComp.offerCompAddressMulti,
            avmPrice: offerComp.avmPrice,
            baths: Math.floor(offerComp.baths),
            bathsDouble: offerComp.baths,
            beds: offerComp.beds,
            conditionScore: offerComp.conditionScore,
            distance: offerComp.offerCompDistance,
            notes: offerComp.offerCompNotes,
            numUnits: offerComp.numUnits,
            selected: true,
            similarityScore: offerComp.similarityScore || 0,
            soldDate: offerComp.offerCompSoldDate,
            source: offerComp.offerCompSource,
            squareFootage: offerComp.squareFootage,
            url: offerComp.offerCompUrl,
            yearBuild: offerComp.yearBuilt,
            flatAvmAdjustment: offerComp.flatAvmAdjustment || 0,
            zipcode: offerComp.zipcode,
          }
          if (offerCompsMap.has(unitName)) {
            offerCompsMap.get(unitName)?.push(offerCompInput)
          } else {
            offerCompsMap.set(unitName, [offerCompInput])
          }
        }
      })
    })
    // generate the rental comps per unit.
    formInputs.marketRentComps.forEach((rentalComp: any) => {
      unitNames.forEach((unitName) => {
        if (rentalComp[`${unitName}RentalCompSelected`]) {
          const rentalCompInput: Core_MarketRentCompInput = {
            address: rentalComp.rentCompAddressMulti,
            distance: rentalComp.rentCompDistance,
            marketRent: rentalComp.rentCompMarketRent,
            notes: rentalComp.rentCompNotes,
            rentalDate: rentalComp.rentCompRentalDate,
            selected: true,
            source: rentalComp.rentCompSource,
            squareFootage: rentalComp.rentCompSquareFootage,
            url: rentalComp.rentCompUrl,
          }
          if (rentalCompsMap.has(unitName)) {
            rentalCompsMap.get(unitName)?.push(rentalCompInput)
          } else {
            rentalCompsMap.set(unitName, [rentalCompInput])
          }
        }
      })
    })

    // for each entry in address map
    const unitInputs: Core_MultiFamilyValuationUnitInputInput[] = unitNames.map(
      (unitName) => ({
        additionalMonthlyCost:
          formInputs[`${unitName}AdditionalMonthlyCost`] || 0,
        additionalMonthlyCostNotes:
          formInputs[`${unitName}AdditionalMonthlyCostNotes`],
        isSectionEight: formInputs[`${unitName}IsSectionEight`] === 'yes',
        unitName,
        analystProjectedRent: formInputs[`${unitName}AnalystProjectedRent`],
        avmProjectedRent: formInputs[`${unitName}AvmProjectedRent`],
        baths: Math.floor(formInputs[`${unitName}Baths`]),
        bathsDouble: formInputs[`${unitName}Baths`],
        beds: formInputs[`${unitName}Beds`],
        currentRent: formInputs[`${unitName}CurrentRent`] || 0,
        currentlyOccupied: formInputs[`${unitName}CurrentlyOccupied`] === 'yes',
        undergoingEviction: formInputs[`${unitName}UndergoingEviction`],
        marketRentComps: rentalCompsMap.get(unitName) || [],
        monthsRemainingOnLease:
          formInputs[`${unitName}MonthsRemainingOnLease`] || 0,
        offerPriceComps: offerCompsMap.get(unitName) || [],
        remodelCost: {
          score: formInputs[`${unitName}RemodelCostScore`],
          healthAndSafetyCapex:
            formInputs[`${unitName}HealthAndSafetyCapex`] || 0,
          immediateAddressableCapex:
            formInputs[`${unitName}ImmediateAddressableCapex`] +
              formInputs[`${unitName}HealthAndSafetyCapex`] || 0,
          totalAddressableCapex:
            formInputs[`${unitName}TotalAddressableCapex`] +
              formInputs[`${unitName}ImmediateAddressableCapex`] +
              formInputs[`${unitName}HealthAndSafetyCapex`] || 0,
        },
        rentNotes: formInputs[`${unitName}RentNotes`],
        squareFootage: formInputs[`${unitName}Sqft`],
      })
    )
    const propertyInput: Core_MultiFamilyValuationPropertyInputInput = {
      otherCosts: {
        acquisition: formInputs.acquisitionCosts || 0,
        miscellaneous: formInputs.miscellaneousCosts || 0,
        hoaExists: formInputs.hoaExists === 'yes',
        hoa: formInputs.yearlyHoaFee || 0,
        addressNearbyHomeHoa: formInputs.nearbyHoaAddress,
        urlNearbyHomeHoa: formInputs.nearbyHoaUrl,
        propertyTaxes: formInputs.taxes || 0,
        notes: formInputs.otherCostNotes,
        additionalMonthlyCost: formInputs.additionalMonthlyCost || 0,
        additionalMonthlyCostNotes: formInputs.additionalCostNotes,
        inPlacePropertyTaxes: formInputs.inPlacePropertyTaxes || 0,
      },
      propertyRemodelCost: {
        // only for final valuation
        healthAndSafetyCapex: formInputs.propertyHealthAndSafetyCapex || 0,
        immediateAddressableCapex: propertyImmediateAddressableCapex,
        totalAddressableCapex: propertyTotalAddressableCapex,
        notes: formInputs.remodelCostNotes,
      },
      propertyAvmValue: formInputs.propertyAvmValue,
      propertyFlatAvmAdjustment: formInputs.propertyFlatAvmAdjustment || 0,
      offerPriceNotes: formInputs.offerPriceNotes,
    }

    // get total property level values
    let baths = 0
    let beds = 0
    let sqft = 0
    unitNames.forEach((unitName) => {
      baths += formInputs[`${unitName}Baths`] || 0
      beds += formInputs[`${unitName}Beds`] || 0
      sqft += formInputs[`${unitName}Sqft`] || 0
    })
    let computationDate = null
    if (formInputs.computationDate) {
      computationDate = formInputs.computationDate
    }
    const parsedInputs: Core_ComputeSalesforceAddressMultiFamilyValuationRequestInput =
      {
        contributionMonth: contributionMonthMonth,
        addressId,
        property: {
          baths: Math.floor(baths),
          bathsDouble: baths,
          beds,
          propertyType: propertyVars?.propertyType,
          sqft,
          yearBuilt: formInputs.yearBuilt,
        },
        propertyInput,
        unitInputs,
        valuationType: formInputs.valuationType,
        valuationUuid:
          computedValuationResult?.uuid || valuationInputs?.valuationUuid,
        computationDate,
      }
    setValuationInputs(parsedInputs)
    return parsedInputs
  }

  // returns a boolean representing whether the inputs are falz
  const validateInputs = (
    parsedInputs: Core_ComputeSalesforceAddressMultiFamilyValuationRequestInput
  ) => {
    // validate that all units have 3 offer and rental comps
    for (let i = 0; i < (parsedInputs?.unitInputs?.length || 0); i += 1) {
      // this if line solely for typescript purposes.
      if (parsedInputs?.unitInputs) {
        const unitInput = parsedInputs?.unitInputs[i]
        if (
          (!unitInput.offerPriceComps?.length ||
            unitInput.offerPriceComps?.length < 3) &&
          parsedInputs?.propertyInput?.propertyAvmValue === 0
        ) {
          notify(
            'Not all units have at least 3 offer price comps selected',
            'error'
          )
          return false
        }
        // we don't want to validate all 3 market rent comps because sometimes we stress test initial.
        if (
          parsedInputs?.propertyInput?.propertyAvmValue === 0 &&
          unitInput.marketRentComps?.length !== 3
        ) {
          notify('Not all units have 3 market rent comps selected', 'error')
          return false
        }
        if (parsedInputs?.propertyInput?.propertyAvmValue === 0) {
          const errMessage = getCompsValidationErrorMessage(
            Core_ValuationCategory.ValuationCategoryMultiFamily,
            unitInput.marketRentComps,
            unitInput.offerPriceComps,
            setOfferCompsErrorMessage,
            setRentCompsErrorMessage,
            true,
            unitInput.unitName as string
          )
          if (errMessage) {
            notify(errMessage, 'error')
            return false
          }
        }
      }
    }

    return true
  }

  const submitComputeValuation = async (formInputs: any) => {
    const parsedInputs = parseAndSetInputs(formInputs)
    const inputsValid = validateInputs(parsedInputs)
    if (!inputsValid) {
      return null
    }
    try {
      const result = await computeValuation({
        variables: {
          input: parsedInputs,
        },
      })
      setComputedValuationResult(
        result.data?.computeSalesforceAddressMultiFamilyValuation
          ?.valuation as Core_MultiFamilyValuation
      )
    } catch (e) {
      notify('Failed to compute the valuation - refresh and try again', 'error')
    }
    return null
  }

  return {
    computeValuationFormFormProps,
    computeValuationFormInputConfigs,
    loading,
    operatorUuid,
    rejectValuationModalOpen,
    setRejectValuationModalOpen,
    valuationHistoryModalOpen,
    setValuationHistoryModalOpen,
    submitComputeValuation,
    computedValuationResult,
    valuationInputs,
    validateFormattedFormData,
    canSubmitValuation,
    canRejectValuation,
    openValuationHistoryModal: () => setValuationHistoryModalOpen(true),
    openRejectValuationModal: () => setRejectValuationModalOpen(true),
    formattedAddress,
    numUnits,
    computeValuationLoading,
    addressId,
  }
}

export default useMultiFamilyComputeAddressValuationTab
