import React from 'react'
import {
  Typography,
  LoadingCard,
  useSnackbar,
  flockColors,
} from '@flock/flock-component-library'
import { styled } from '@mui/material/styles'
import { TableRow } from '@mui/material'
import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { AdminGetLastFivetranSyncTimeDocument } from '@flock/flock-gql-server/src/__generated__/graphql'

const PageTitleContainer = styled('div')({
  marginTop: '4rem',
  marginBottom: '3rem',
  display: 'flex',
  justifyContent: 'space-between',
})

export const StyledPropertiesRow = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: flockColors.lighterGray,
    transition: 'background-color 0.5s ease',
  },
})

const InfraPage = (_: RouteComponentProps) => {
  const { notify } = useSnackbar()

  const { loading, data } = useQuery(AdminGetLastFivetranSyncTimeDocument, {
    variables: {},
    onError: () => {
      notify('Failed to get last successful sync', 'error')
    },
  })

  const lastRunTime = data?.getLastFivetranSyncTime
    ?.lastSuccessfulSync as string
  const date = new Date(lastRunTime) // Create a Date object from the UTC timestamp

  // Convert to local time and format it as a string
  const localTime = date.toLocaleString()

  return (
    <>
      <PageTitleContainer />
      {loading ? (
        <LoadingCard text="Loading..." />
      ) : (
        <Typography variant="h2">
          Last Successful Fivetran Run: {localTime}
        </Typography>
      )}
    </>
  )
}

export default InfraPage
