/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const CREATE_ADDRESS_PHOTO = gql`
  mutation AdminCreateAddressPhoto($input: CreateAddressPhotoInput!) {
    createAddressPhoto(input: $input) {
      uuid
    }
  }
`

export const CREATE_ADDRESS_DOCUMENT = gql`
  mutation AdminCreateAddressDocument($input: CreateAddressDocumentInput!) {
    createAddressDocument(input: $input) {
      addressDocuments {
        name
        documentUuid
      }
    }
  }
`

export const UPLOAD_INSURANCE_UPDATE = gql`
  mutation AdminUploadInsuranceUpdate($input: UploadUpdateDocumentInput!) {
    uploadInsuranceUpdateDocument(input: $input)
  }
`

export const UPLOAD_PROPERTY_TAX_UPDATE = gql`
  mutation AdminUploadPropertyTaxUpdate($input: UploadUpdateDocumentInput!) {
    uploadPropertyTaxUpdateDocument(input: $input)
  }
`

export const UPLOAD_TAX_ACCRUAL_UPDATE = gql`
  mutation AdminUploadTaxAccrualUpdate($input: UploadUpdateDocumentInput!) {
    uploadTaxAccrualUpdateDocument(input: $input)
  }
`
