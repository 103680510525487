import React from 'react'
import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import { DatePicker, Dropdown, TrackedButton } from '@flock/shared-ui'

import useCreateSowProjectModule from './useCreateSowProjectModule'
import {
  CreateSowProjectModuleProps,
  projectTypeOptions,
} from './createSowProjectModuleTypes'

const LabelledField = (props: { label: string; children: React.ReactNode }) => {
  const { label, children } = props
  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <Typography variant="ctam" height="24px">
        {label}
      </Typography>
      {children}
    </Box>
  )
}

const CreateSowProjectModule = (props: CreateSowProjectModuleProps) => {
  const presentationalProps = useCreateSowProjectModule(props)
  const {
    projectType,
    setProjectType,
    inspectionDate,
    setInspectionDate,
    addressID,
    projectID,
    costbook,
    costbookOptions,
    scope,
    scopeOptions,

    setAddressID,
    setProjectID,
    setCostbook,
    setScope,

    loading,

    onSubmit,
  } = presentationalProps

  return (
    <Box
      display="flex"
      gap="16px"
      padding="16px"
      borderRadius="16px"
      sx={{
        backgroundColor: 'gray1.main',
      }}
    >
      <Box minWidth="156px">
        <LabelledField label="Type">
          <Dropdown
            variant="filled"
            options={projectTypeOptions}
            value={projectType}
            onChange={(e) => setProjectType(e.target.value)}
          />
        </LabelledField>
      </Box>
      <Box width="100%">
        <LabelledField label="Address ID">
          <TextField
            variant="filled"
            value={addressID}
            placeholder="a0hUt000000KMijIAG"
            onChange={(e) => setAddressID(e.target.value)}
          />
        </LabelledField>
      </Box>
      {projectType === 'inside_maps' ? (
        <Box width="100%">
          <LabelledField label="Project ID">
            <TextField
              variant="filled"
              value={projectID}
              placeholder="W5FxyztxUl"
              onChange={(e) => setProjectID(e.target.value)}
            />
          </LabelledField>
        </Box>
      ) : (
        <Box width="100%">
          <LabelledField label="Inspection Date">
            <DatePicker
              variant="filled"
              value={inspectionDate}
              placeholder="W5FxyztxUl"
              onChange={(e) => setInspectionDate(e.target.value)}
              disabled
            />
          </LabelledField>
        </Box>
      )}
      <LabelledField label="Scope">
        <Box width="230px">
          <Dropdown
            variant="filled"
            options={scopeOptions}
            value={scope}
            onChange={(e) => setScope(e.target.value)}
          />
        </Box>
      </LabelledField>
      <LabelledField label="Costbook">
        <Box width="230px">
          <Dropdown
            variant="filled"
            options={costbookOptions}
            value={costbook}
            onChange={(e) => setCostbook(e.target.value)}
          />
        </Box>
      </LabelledField>
      <LabelledField label="">
        <TrackedButton
          onClick={onSubmit}
          disabled={loading}
          sx={{
            width: '136px',
          }}
        >
          {loading ? (
            <CircularProgress size="28px" />
          ) : (
            <Typography variant="ctam" noWrap>
              Add Project
            </Typography>
          )}
        </TrackedButton>
      </LabelledField>
    </Box>
  )
}

export default CreateSowProjectModule
