import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { AnyInputConfig, InputType, useSnackbar } from '@flock/shared-ui'
import { Typography } from '@flock/flock-component-library'
import {
  DEFAULT_CLOSING_AND_LEGAL_FEE,
  DEFAULT_ONBOARDING_FEE,
  formatIntegerDollars,
} from '@flock/utils'
import {
  ComputeCashFlowFormParams,
  ComputeCashFlowModalProps,
} from './computeCashFlowModalTypes'

const COMPUTE_CASH_FLOW = gql`
  mutation ComputeCashFlow($input: Core_ComputeCashFlowRequestInput!) {
    computeCashFlow(input: $input) {
      cashOnCashYield
    }
  }
`

const useComputeCashFlowModal = (props: ComputeCashFlowModalProps) => {
  const {
    finalOfferPrice,
    immediateRemodelCosts,
    netYieldAtBasis,
    addressMortgageAmount,
  } = props

  const { notify } = useSnackbar()
  const [cashNeedsDollars, setCashNeedsDollars] = useState(0)
  const [cashNeedsPercent, setCashNeedsPercent] = useState(0)
  const [deltaToRedline, setDeltaToRedline] = useState(0)
  const [cashOnCashYield, setCashOnCashYield] = useState(0)
  const [computeCashFlow, { loading }] = useMutation(COMPUTE_CASH_FLOW)

  const computeCashFlowInputConfigs: AnyInputConfig[] = [
    {
      name: 'finalOfferPrice',
      type: InputType.CustomComponent,
      gridItemProps: {
        sm: 4,
      },
      props: {
        component: (
          <Typography variant="h3">
            {`Flock Offer Price: ${formatIntegerDollars(
              Math.trunc(finalOfferPrice)
            )}`}
          </Typography>
        ),
      },
    },
    {
      name: 'remodelCosts',
      type: InputType.CustomComponent,
      gridItemProps: {
        sm: 4,
      },
      props: {
        component: (
          <Typography variant="body1">
            {`Remodel Costs (Immediate): ${formatIntegerDollars(
              Math.trunc(immediateRemodelCosts)
            )}`}
          </Typography>
        ),
      },
    },
    {
      name: 'netYieldAtBasis',
      type: InputType.CustomComponent,
      gridItemProps: {
        sm: 4,
      },
      props: {
        component: (
          <Typography variant="body1">
            {`Net Yield at Basis: ${(netYieldAtBasis * 100).toFixed(2)}%`}
          </Typography>
        ),
      },
    },
    {
      name: 'onboardingFee',
      type: InputType.Text,
      gridItemProps: {
        sm: 4,
      },
      defaultValue: DEFAULT_ONBOARDING_FEE * 100,
      props: {
        label: 'Onboarding Fee (%)',
        size: 'small',
        required: true,
        format: 'percent',
      },
    },
    {
      name: 'brokerFee',
      type: InputType.Text,
      gridItemProps: {
        sm: 4,
      },
      defaultValue: 0,
      props: {
        label: 'Broker Fee',
        size: 'small',
        required: true,
        format: 'percent',
      },
    },
    {
      name: 'closingCosts',
      type: InputType.Text,
      gridItemProps: {
        sm: 4,
      },
      defaultValue: DEFAULT_CLOSING_AND_LEGAL_FEE * 100,
      props: {
        label: 'Closing Costs',
        size: 'small',
        required: true,
        format: 'percent',
      },
    },
    {
      name: 'mortgageAmount',
      type: InputType.Text,
      gridItemProps: {
        sm: 4,
      },
      defaultValue: addressMortgageAmount,
      props: {
        label: 'Mortgage Amount',
        size: 'small',
        format: 'number',
        required: true,
      },
    },
    {
      name: 'cashTakeout',
      type: InputType.Text,
      gridItemProps: {
        sm: 4,
      },
      defaultValue: 0,
      props: {
        label: 'Cash Takeout',
        size: 'small',
        format: 'number',
        required: true,
      },
    },
    {
      name: 'cashPaydown',
      type: InputType.Text,
      gridItemProps: {
        sm: 4,
      },
      defaultValue: 0,
      props: {
        label: 'Cash Paydown',
        size: 'small',
        format: 'number',
        required: true,
      },
    },
    {
      name: 'computationDate',
      type: InputType.DatePicker,
      defaultValue: '',
      gridItemProps: {
        sm: 6,
      },
      props: {
        size: 'small',
        label: 'Computation Date',
      },
    },
    {
      name: 'fiveYearTreasuryRate',
      type: InputType.Text,
      required: false,
      gridItemProps: {
        sm: 6,
      },
      props: {
        label: 'Five Year Treasury Rate',
        size: 'small',
        format: 'percent',
        placeholder: 'Leave blank to use current rate',
      },
    },
  ]

  const onSubmit = async (computeCashFlowParams: ComputeCashFlowFormParams) => {
    const {
      fiveYearTreasuryRate,
      closingCosts,
      onboardingFee,
      mortgageAmount,
      cashTakeout,
      cashPaydown,
      brokerFee,
    } = computeCashFlowParams

    let computationDate = null
    if (computeCashFlowParams.computationDate) {
      computationDate = computeCashFlowParams.computationDate
    }

    const cashNeedsDollarsCalc =
      (closingCosts * finalOfferPrice) / 100 +
      (onboardingFee * finalOfferPrice) / 100 +
      (brokerFee * finalOfferPrice) / 100 +
      mortgageAmount +
      immediateRemodelCosts -
      cashPaydown +
      cashTakeout

    const cashNeedsPercentCalc =
      cashNeedsDollarsCalc / (finalOfferPrice + immediateRemodelCosts)
    const deltaToRedlineCalc =
      0.5 * (finalOfferPrice + immediateRemodelCosts) - cashNeedsDollarsCalc

    setCashNeedsDollars(cashNeedsDollarsCalc)
    setDeltaToRedline(deltaToRedlineCalc)
    setCashNeedsPercent(cashNeedsPercentCalc)
    try {
      const { data } = await computeCashFlow({
        variables: {
          input: {
            onboardingFee: onboardingFee / 100,
            brokerFee: brokerFee / 100,
            closingCosts: closingCosts / 100,
            mortgageAmount,
            cashTakeout,
            cashPaydown,
            fiveYearTreasuryRate: fiveYearTreasuryRate / 100 || 0,
            computationDate,
            finalOfferPrice,
            immediateRemodelCosts,
            netYieldAtBasis,
          },
        },
      })

      setCashOnCashYield(data.computeCashFlow.cashOnCashYield)
    } catch (e) {
      notify('Computing cash flow failed, please try again', 'error')
    }
  }

  return {
    computeCashFlowInputConfigs,
    onSubmit,
    cashNeedsDollars,
    cashNeedsPercent,
    deltaToRedline,
    cashOnCashYield,
    loading,
  }
}

export default useComputeCashFlowModal
