import React from 'react'
import { Download } from 'phosphor-react'
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Table,
  IconButton,
  Switch,
  Link,
} from '@mui/material'
import useAccountStaticDocumentsTable from './useAccountStaticDocumentsTable'
import { AccountStaticDocumentsTableProps } from './accountStaticDocumentsTableTypes'
import LoadingCard from '../../LoadingCard'
import {
  CUSTOMER_TESTIMONIAL_BRODBECK,
  CUSTOMER_TESTIMONIAL_COMPILATION,
  CUSTOMER_TESTIMONIAL_GHOENS,
  CUSTOMER_TESTIMONIAL_KREIS,
  CUSTOMER_TESTIMONIAL_WIZELMAN,
} from '../../../constants'

const AccountStaticDocumentsTable = (
  props: AccountStaticDocumentsTableProps
) => {
  const presentationalProps = useAccountStaticDocumentsTable(props)
  const {
    isDirty,
    saveLoading,
    loading,
    staticDocumentConfigs,
    currentOverrides,
    onToggle,
    onSave,
    accountId,
  } = presentationalProps

  if (loading) {
    return <LoadingCard text="" />
  }

  return (
    <Box display="flex" flexDirection="column" gap="48px">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">Universal Document Toggles</Typography>
        <Button
          variant="primary"
          size="smallForm"
          onClick={onSave}
          disabled={!isDirty || saveLoading}
        >
          {saveLoading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </Box>
      {staticDocumentConfigs.map((documentConfig) => {
        const { title, documents } = documentConfig
        return (
          <Box key={title} display="flex" flexDirection="column" gap="16px">
            <Typography variant="h4">{title}</Typography>
            <Table>
              <TableHead>
                <TableCell>Name</TableCell>
                <TableCell>Full Link</TableCell>
                <TableCell>Download</TableCell>
                <TableCell>Visible</TableCell>
              </TableHead>
              <TableBody>
                {documents.map((document) => {
                  const { uuid, name, defaultEnabled } = document
                  let { contentUrl } = document

                  // Adjust contentURL for non-document media
                  // Be sure to also adjust in DoucmentRenderer.tsx
                  if (name.includes('Trustpilot')) {
                    contentUrl =
                      'https://www.trustpilot.com/review/flockhomes.com'
                  } else if (name.includes('Q2 2023 Investor Call')) {
                    contentUrl = 'https://vimeo.com/850676028/814c557453'
                  } else if (name.includes('Q3 2023 Investor Call')) {
                    contentUrl = 'https://vimeo.com/882234151/79de8d80c1'
                  } else if (name.includes('Q4 2023 Investor Call')) {
                    contentUrl = 'https://vimeo.com/908392542/0c01ca938c'
                  } else if (name.includes('Q1 2024 Investor Call')) {
                    contentUrl = 'https://vimeo.com/938798610/513b2d9c7d'
                  } else if (name.includes('Q2 2024 Investor Call')) {
                    contentUrl = 'https://vimeo.com/989758072/42b0fdfe4f'
                  } else if (name.includes('Q3 2024 Investor Call')) {
                    contentUrl = 'https://vimeo.com/1023064774/db05656dd9'
                  } else if (name.includes('Flock Webinar')) {
                    contentUrl = 'https://vimeo.com/manage/videos/873848983'
                  } else if (name.includes('Customer Testimonial - Brodbeck')) {
                    contentUrl = CUSTOMER_TESTIMONIAL_BRODBECK
                  } else if (name.includes('Customer Testimonial - Ghoens')) {
                    contentUrl = CUSTOMER_TESTIMONIAL_GHOENS
                  } else if (name.includes('Customer Testimonial - Kreis')) {
                    contentUrl = CUSTOMER_TESTIMONIAL_KREIS
                  } else if (name.includes('Customer Testimonial - Wizelman')) {
                    contentUrl = CUSTOMER_TESTIMONIAL_WIZELMAN
                  } else if (
                    name.includes('Customer Testimonial - Compilation')
                  ) {
                    contentUrl = CUSTOMER_TESTIMONIAL_COMPILATION
                  } else {
                    contentUrl = `https://flockhomes.com/account-estimate/${accountId}/document?documentPath=${contentUrl!}`
                  }

                  const currentValue = currentOverrides[uuid] ?? defaultEnabled

                  return (
                    <TableRow key={uuid}>
                      <TableCell width="412px">{name}</TableCell>
                      <TableCell width="800px">
                        <Box display="flex" gap="16px">
                          <Link href={contentUrl} fontSize="12px">
                            {contentUrl}
                          </Link>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" gap="16px">
                          <IconButton
                            onClick={() => window.open(contentUrl!, '_blank')}
                          >
                            <Download />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={currentValue}
                          onChange={() => onToggle(uuid, !currentValue)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        )
      })}
    </Box>
  )
}

export default AccountStaticDocumentsTable
