import { useMutation } from '@apollo/client'
import {
  AdminCreateScopeOfWorkAssetDocument,
  AdminCreateScopeOfWorkAssetMediaDocument,
  AdminDeleteScopeOfWorkAssetDocument,
  AdminDeleteScopeOfWorkAssetMediaDocument,
  AdminUpdateScopeOfWorkAssetDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import usePhotoGallery from '../PhotoGallery/usePhotoGallery'
import { useSowSaveContext } from '../SowSaveContextProvider'
import {
  SowAssetModalProps,
  SowAssetModalPresentationalProps,
} from './sowAssetModalTypes'

const useSowAssetModal: (
  props: SowAssetModalProps
) => SowAssetModalPresentationalProps = (props: SowAssetModalProps) => {
  const { onClose, asset, projectUUID } = props

  const [category, setCategory] = useState(asset?.category || '')
  const [location, setLocation] = useState(asset?.location || '')
  const [assetText, setAssetText] = useState(asset?.item || '')
  const [month, setMonth] = useState(asset?.month || 0)
  const [year, setYear] = useState(asset?.year || 0)
  const [status, setStatus] = useState(asset?.status || '')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [endOfLifeDate, setEndOfLifeDate] = useState<Date | null | undefined>(
    asset?.endOfLifeDate
  )
  const [remainingLife, setRemainingLife] = useState(0)
  const [recommendedAdjustment, setRecommendedAdjustment] = useState(0)
  const [loading, setLoading] = useState(false)

  const [serialNumber, setSerialNumber] = useState(asset?.serialNumber || '')
  const [model, setModel] = useState(asset?.modelNumber || '')
  const [manufacturer, setManufacturer] = useState(asset?.manufacturer || '')
  const [color, setColor] = useState(asset?.color || '')

  const {
    photos,
    selectedPhoto,
    photosToAdd,
    setPhotos,
    setSelectedPhoto,
    onAddPhotos,
    onDeletePhoto,
    setPhotosToAdd,
  } = usePhotoGallery()

  const { notify } = useSnackbar()
  const { onSaveSuccess, onSaveError, setSaveLoading, refetchProject } =
    useSowSaveContext()

  const [updateSOWAsset] = useMutation(AdminUpdateScopeOfWorkAssetDocument)
  const [createSOWAsset] = useMutation(AdminCreateScopeOfWorkAssetDocument)
  const [deleteSOWAsset] = useMutation(AdminDeleteScopeOfWorkAssetDocument)
  const [deleteSOWAssetMedia] = useMutation(
    AdminDeleteScopeOfWorkAssetMediaDocument
  )
  const [createSOWAssetMedia] = useMutation(
    AdminCreateScopeOfWorkAssetMediaDocument
  )

  useEffect(() => {
    if (asset) {
      setCategory(asset.category)
      setLocation(asset.location)
      setAssetText(asset.item)
      setMonth(asset.month)
      setYear(asset.year)
      setStatus(asset.status)
      setEndOfLifeDate(asset.endOfLifeDate)
      // Set the photos
      const newPhotos = asset.media.map((photo) => ({
        url: photo.s3Url,
        addPhotosIndex: -1,
      }))
      setPhotos(newPhotos)
      setSelectedPhoto(
        newPhotos.length > 0 ? newPhotos[0] : { url: '', addPhotosIndex: -1 }
      )
      setSerialNumber(asset.serialNumber)
      setModel(asset.modelNumber)
      setManufacturer(asset.manufacturer)
      setColor(asset.color)
    } else {
      setCategory('')
      setLocation('')
      setAssetText('')
      setMonth(0)
      setYear(0)
      setStatus('')
      setEndOfLifeDate(new Date())
      setPhotos([])
      setSelectedPhoto({ url: '', addPhotosIndex: -1 })
    }
    setPhotosToAdd([])
  }, [asset, setPhotos, setSelectedPhoto, setPhotosToAdd])

  useEffect(() => {
    if (endOfLifeDate) {
      const now = new Date()
      const endOfLife = new Date(endOfLifeDate)
      const totalMonths =
        (endOfLife.getFullYear() - (year || now.getFullYear())) * 12 +
        (endOfLife.getMonth() - (year ? month : now.getMonth()))
      const remainingMonths =
        (endOfLife.getFullYear() - now.getFullYear()) * 12 +
        (endOfLife.getMonth() - now.getMonth())
      let remaining = Math.floor((remainingMonths / totalMonths) * 100)
      if (remaining < 0 || endOfLife < now) {
        remaining = 0
      }
      setRemainingLife(remaining)

      // If between 0% and 24% remaining life, recommend a 100% adjustment.
      // If between 25% and 49% remaining life, recommend a 75% adjustment.
      // If between 50% and 74% remaining life, recommend a 50% adjustment.
      // If between 75% and 100% remaining life, recommend a 0% adjustment

      if (remaining >= 0 && remaining <= 24) {
        setRecommendedAdjustment(100)
      } else if (remaining >= 25 && remaining <= 49) {
        setRecommendedAdjustment(75)
      } else if (remaining >= 50 && remaining <= 74) {
        setRecommendedAdjustment(50)
      } else if (remaining >= 75 && remaining <= 100) {
        setRecommendedAdjustment(0)
      }
    }
  }, [month, year, endOfLifeDate])

  const onSave = async () => {
    setSaveLoading(true)
    setLoading(true)
    let assetUuid = asset?.uuid || ''

    try {
      if (!asset?.uuid) {
        const resp = await createSOWAsset({
          variables: {
            input: {
              sowAsset: {
                assetCategory: category,
                assetLocation: location,
                item: assetText,
                assetMonth: month,
                assetYear: year,
                status,
                source: 'manual',
                notes: '',
                endOfLifeDate,
                uuid: uuidv4(),
                sowProjectUuid: projectUUID || '',
                serialNumber,
                modelNumber: model,
                manufacturer,
                color,
              },
            },
          },
        })

        assetUuid = resp.data?.createSOWAsset?.uuid || ''
      } else {
        await updateSOWAsset({
          variables: {
            input: {
              uuid: asset.uuid,
              assetCategory: category,
              assetLocation: location,
              item: assetText,
              assetMonth: month,
              assetYear: year,
              status,
              notes: '',
              endOfLifeDate,
              serialNumber,
              modelNumber: model,
              manufacturer,
              color,
            },
          },
        })
      }

      // For photos, iterate through the photos array. If the photo is in the deficiency media, do nothing. If it's not, upload the photo using the photosToAdd array.
      // If it's in the deficiency media but not in the photos array, delete the photo.
      if (asset) {
        for (let i = 0; i < asset.media.length; i += 1) {
          if (!photos.find((photo) => photo.url === asset.media[i].s3Url)) {
            await deleteSOWAssetMedia({
              variables: {
                input: {
                  uuid: asset.media[i].uuid,
                },
              },
            })
          }
        }
      }

      // Upload all of the photosToAdd
      for (let i = 0; i < photosToAdd.length; i += 1) {
        await createSOWAssetMedia({
          variables: {
            input: {
              sowAssetUuid: assetUuid || '',
              file: photosToAdd[i],
              mediaType: photosToAdd[i].type,
            },
          },
        })
      }

      await refetchProject()
      onSaveSuccess()
      notify('Asset saved successfully', 'success')
    } catch (e) {
      notify(`Error saving asset ${e.message}`, 'error')
      onSaveError(`Failed to save asset: ${e.message}`)
    }
    setLoading(false)
  }

  const onDelete = async () => {
    setSaveLoading(true)
    setLoading(true)
    try {
      if (asset) {
        await deleteSOWAsset({
          variables: {
            input: {
              uuid: asset.uuid,
            },
          },
          // refetchQueries: [],
        })
        onSaveSuccess()
        onClose()
      } else {
        onClose()
      }
    } catch (e) {
      notify(`Error deleting asset: ${e.message}`, 'error')
      onSaveError(`Failed to delete asset: ${e.message}`)
    }
    setLoading(false)
  }

  return {
    ...props,
    category,
    setCategory,
    location,
    setLocation,
    assetText,
    setAssetText,
    month,
    setMonth,
    year,
    setYear,
    status,
    setStatus,
    endOfLifeDate,
    setEndOfLifeDate,
    remainingLife,
    recommendedAdjustment,

    serialNumber,
    setSerialNumber,
    model,
    setModel,
    manufacturer,
    setManufacturer,
    color,
    setColor,

    photos,
    selectedPhoto,
    setSelectedPhoto,
    onAddPhotos,
    onDeletePhoto,
    loading,
    onSave,
    onDelete,

    setShowDeleteModal,
    showDeleteModal,
  }
}

export default useSowAssetModal
