import { useMutation } from '@apollo/client'
import { AdminUpdateScopeOfWorkItemSolutionDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { FormattedTextField, useSnackbar } from '@flock/shared-ui'
import {
  Autocomplete,
  TextField,
  Box,
  Typography,
  ClickAwayListener,
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SolutionRowContainer from '../../SolutionRowContainer'
import {
  adjustmentOptions,
  LabelValueOption,
  SowCostCatalog,
  SowItemSolution,
  uomOptions,
  urgencyOptions,
} from '../individualSowProjectPageTypes'
import { useSowSaveContext } from '../SowSaveContextProvider'

const autocompleteSlotProps = {
  paper: {
    sx: {
      '& .MuiAutocomplete-listbox': {
        '& .MuiAutocomplete-option': {
          fontSize: '12px',
          paddingX: '6px',
          paddingY: '3px',
        },
      },
    },
  },
}

type SolutionRowProps = SowItemSolution & {
  costCatalog: SowCostCatalog
  solutionOptions: string[]
  keywords: string[]
}

const FlaggableBox = (props: { flag: boolean; children: React.ReactNode }) => {
  const { flag, children } = props
  return (
    <Box
      sx={{
        backgroundColor: flag ? 'softGold.main' : 'transparent',
      }}
    >
      {children}
    </Box>
  )
}

const SolutionRow = (props: SolutionRowProps) => {
  const {
    uuid,
    itemCode,
    category,
    solution,
    description,
    unitOfMeasurement,
    unitPrice,
    quantity,
    urgency,
    adjustment,
    costCatalog,
    keywords,
    solutionOptions: deficiencySolutions,
  } = props
  const urgencyObject = urgencyOptions.find((item) => item.value === urgency)
  const adjustmentObject = adjustmentOptions.find(
    (item) => item.value === adjustment
  )

  const [curCategory, setCurCategory] = useState(category)
  const [curSolution, setCurSolution] = useState(solution)
  const [curDescription, setCurDescription] = useState(description)
  const [curUnitOfMeasurement, setCurUnitOfMeasurement] =
    useState(unitOfMeasurement)
  const [curUnitPrice, setCurUnitPrice] = useState(unitPrice || 0)
  const [curQuantity, setCurQuantity] = useState(quantity || 0)
  const [curUrgency, setCurUrgency] = useState<LabelValueOption>(
    urgencyObject || urgencyOptions[0]
  )
  const [curAdjustment, setCurAdjustment] = useState(
    adjustmentObject || adjustmentOptions[0]
  )
  const [curTotalCost, setCurTotalCost] = useState(0)

  // For flagging if the set solution is the same as one in the costbook
  const [curSolutionCode, setCurSolutionCode] = useState(itemCode)
  const [originalSolution, setOriginalSolution] = useState('')
  const [originalCostPerItem, setOriginalCostPerItem] = useState(0)
  const [originalUom, setOriginalUom] = useState('')
  const [originalDescription, setOriginalDescription] = useState('')

  const [flagCostPerItemDifferent, setFlagCostPerItemDifferent] =
    useState(false)
  const [flagDescriptionDifferent, setFlagDescriptionDifferent] =
    useState(false)
  const [flagUomDifferent, setFlagUomDifferent] = useState(false)
  const [flagSolutionDifferent, setFlagSolutionDifferent] = useState(false)

  useEffect(() => {
    // Sets the current originals
    if (!curCategory || !costCatalog[curCategory]) {
      setOriginalCostPerItem(0)
      setOriginalSolution('')
      setOriginalUom('')
      setOriginalDescription('')
      return
    }
    const originalItem = costCatalog[curCategory].find(
      (catalogRow) => catalogRow.itemCode === curSolutionCode
    )

    if (originalItem) {
      setOriginalCostPerItem(originalItem.price)
      setOriginalSolution(originalItem.solution)
      setOriginalUom(originalItem.uom)
      setOriginalDescription(originalItem.description)
    }
  }, [curSolutionCode])

  const categoryOptions = useMemo(() => Object.keys(costCatalog), [costCatalog])
  const solutionOptions = useMemo(() => {
    if (!curCategory || !costCatalog[curCategory]) return []
    return Array.from(
      new Set(
        costCatalog[curCategory]
          .map((item) => item.solution)
          .sort((a, b) => {
            // Check if 'a' or 'b' contains any keyword
            const aHasKeyword = keywords.some((keyword) =>
              a.toLowerCase().includes(keyword.toLowerCase())
            )
            const bHasKeyword = keywords.some((keyword) =>
              b.toLowerCase().includes(keyword.toLowerCase())
            )

            // Sort by whether they contain keywords
            if (aHasKeyword && !bHasKeyword) return -1
            if (!aHasKeyword && bHasKeyword) return 1

            // check if 'a' or 'b' are in the deficiencySolutions
            const aInSolutions = deficiencySolutions.includes(a)
            const bInSolutions = deficiencySolutions.includes(b)

            // Sort by whether they are in the deficiencySolutions
            if (aInSolutions && !bInSolutions) return -1
            if (!aInSolutions && bInSolutions) return 1

            return 0 // No change in order if both or neither have keywords
          })
      )
    )
  }, [curCategory, costCatalog, keywords, deficiencySolutions])

  const descriptionOptions = useMemo(() => {
    if (!curCategory || !costCatalog[curCategory]) return []
    // The description options are based on the currently selected solution
    // We find every solution in the costCatalog that matches the current solution
    // and return the descriptions for those solutions
    const options = costCatalog[curCategory]
      .filter((item) => item.solution === curSolution && item.description)
      .map((item) => item.description)

    return options
  }, [curCategory, curSolution, costCatalog, deficiencySolutions])

  const [editMode, setEditMode] = useState(false)

  const { notify } = useSnackbar()
  const { onSaveSuccess, onSaveError, setSaveLoading, refetchProject } =
    useSowSaveContext()

  const onSetCurSolution = (newSolution: string) => {
    setCurSolution(newSolution)

    // Check if new solution exists in the costCatalog
    if (!curCategory || !costCatalog[curCategory]) return
    if (
      costCatalog[curCategory].find((item) => item.solution === newSolution)
    ) {
      const item = costCatalog[curCategory].find(
        (catalogRow) => catalogRow.solution === newSolution
      )
      if (!item) return

      setCurUnitPrice(item.price)
      setCurUnitOfMeasurement(item.uom)
      setCurSolutionCode(item.itemCode)
      setCurDescription(item.description)
    }
  }

  const onSetCurDescription = (newDescription: string) => {
    // Check if the current solution exists in the catalog
    setCurDescription(newDescription)

    if (!curCategory || !costCatalog[curCategory]) return
    const item = costCatalog[curCategory].find(
      (catalogRow) =>
        catalogRow.solution === curSolution &&
        catalogRow.description === newDescription
    )

    if (item) {
      setCurSolution(item.solution)
      setCurUnitPrice(item.price)
      setCurUnitOfMeasurement(item.uom)
      setCurSolutionCode(item.itemCode)
    }
  }

  const [updateSOWItemSolution] = useMutation(
    AdminUpdateScopeOfWorkItemSolutionDocument
  )

  useEffect(() => {
    const newTotalCost = curUnitPrice * curQuantity * curAdjustment.value
    setCurTotalCost(newTotalCost)
  }, [curUnitPrice, curQuantity, curAdjustment])

  useEffect(() => {
    if (originalSolution && curSolution !== originalSolution) {
      setFlagSolutionDifferent(true)
    } else {
      setFlagSolutionDifferent(false)
    }
  }, [curSolution, originalSolution])

  useEffect(() => {
    if (curUnitPrice !== originalCostPerItem) {
      setFlagCostPerItemDifferent(true)
    } else {
      setFlagCostPerItemDifferent(false)
    }
  }, [curUnitPrice, originalCostPerItem])

  useEffect(() => {
    if (originalDescription && curDescription !== originalDescription) {
      setFlagDescriptionDifferent(true)
    } else {
      setFlagDescriptionDifferent(false)
    }
  }, [curDescription, originalDescription])

  useEffect(() => {
    if (curUnitOfMeasurement !== originalUom) {
      setFlagUomDifferent(true)
    } else {
      setFlagUomDifferent(false)
    }
  }, [curUnitOfMeasurement, originalUom])

  const updateSolution = useCallback(
    async (
      newCategory: string,
      newSolution: string,
      newDescription: string,
      newUnitOfMeasurement: string,
      newUnitPrice: number,
      newQuantity: number,
      newUrgency: string,
      newAdjustment: number,
      newSolutionCode: string
    ) => {
      setSaveLoading(true)

      try {
        await updateSOWItemSolution({
          variables: {
            input: {
              uuid,
              category: newCategory,
              solution: newSolution,
              itemSolutionDescription: newDescription,
              unitOfMeasurement: newUnitOfMeasurement,
              unitCost: newUnitPrice,
              quantity: newQuantity,
              adjustment: newAdjustment,
              acquisitionScopeCategory: newUrgency,
              costbookItemCode: newSolutionCode,
            },
          },
        })
        await refetchProject()
        onSaveSuccess()
      } catch (e) {
        notify('Failed to update solution', 'error')
        onSaveError(`Failed to update solution: ${e.message}`)
      }
    },
    [
      notify,
      onSaveError,
      onSaveSuccess,
      setSaveLoading,
      updateSOWItemSolution,
      uuid,
    ]
  )

  const onClickAway = () => {
    setEditMode(false)
    updateSolution(
      curCategory,
      curSolution,
      curDescription,
      curUnitOfMeasurement,
      curUnitPrice,
      curQuantity,
      curUrgency.value,
      curAdjustment.value,
      curSolutionCode
    )
  }

  if (!editMode) {
    return (
      <Box
        onClick={() => {
          setEditMode(true)
        }}
        pb="8px"
      >
        <SolutionRowContainer>
          <Box>
            <Typography variant="p4">{curCategory}</Typography>
          </Box>
          <FlaggableBox flag={flagSolutionDifferent}>
            <Typography variant="p4">{curSolution}</Typography>
          </FlaggableBox>
          <FlaggableBox flag={flagDescriptionDifferent}>
            <Typography variant="p4">{curDescription}</Typography>
          </FlaggableBox>
          <FlaggableBox flag={flagUomDifferent}>
            <Typography variant="p4">{curUnitOfMeasurement}</Typography>
          </FlaggableBox>
          <FlaggableBox flag={flagCostPerItemDifferent}>
            <Typography variant="p4">{`$${curUnitPrice.toLocaleString()}`}</Typography>
          </FlaggableBox>
          <Box>
            <Typography variant="p4">{curQuantity}</Typography>
          </Box>
          <Box>
            <Typography variant="p4">{curUrgency.label}</Typography>
          </Box>
          <Box>
            <Typography variant="p4">{curAdjustment.label}</Typography>
          </Box>
          <Box>
            <Typography variant="p4">{`$${curTotalCost.toLocaleString()}`}</Typography>
          </Box>
        </SolutionRowContainer>
      </Box>
    )
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box
        sx={{
          borderRadius: '4px',
          backgroundColor: 'green0.main',
        }}
        pb="8px"
      >
        <SolutionRowContainer>
          <Autocomplete
            value={curCategory}
            onChange={(_, value) => setCurCategory(value)}
            options={categoryOptions}
            disableClearable
            placeholder="Category"
            slotProps={autocompleteSlotProps}
            renderInput={(params) => (
              <TextField {...params} multiline variant="standard" size="mini" />
            )}
          />
          <FlaggableBox flag={flagSolutionDifferent}>
            <Autocomplete
              value={curSolution}
              onInputChange={(_, value) => onSetCurSolution(value || '')}
              options={solutionOptions}
              disableClearable
              placeholder="Solution"
              freeSolo
              slotProps={autocompleteSlotProps}
              renderInput={(params) => (
                <TextField
                  {...params}
                  multiline
                  variant="standard"
                  size="mini"
                  placeholder="Solution"
                />
              )}
            />
          </FlaggableBox>
          <FlaggableBox flag={flagDescriptionDifferent}>
            <Autocomplete
              value={curDescription}
              onInputChange={(_, value) => onSetCurDescription(value || '')}
              options={descriptionOptions}
              disableClearable
              placeholder="Description"
              freeSolo
              slotProps={autocompleteSlotProps}
              renderInput={(params) => (
                <TextField
                  {...params}
                  multiline
                  variant="standard"
                  size="mini"
                  placeholder="Description"
                />
              )}
            />
          </FlaggableBox>
          <FlaggableBox flag={flagUomDifferent}>
            <Autocomplete
              value={curUnitOfMeasurement}
              onChange={(_, value) => setCurUnitOfMeasurement(value || '')}
              options={uomOptions}
              disableClearable
              placeholder="UOM"
              slotProps={autocompleteSlotProps}
              renderInput={(params) => (
                <TextField
                  {...params}
                  multiline
                  variant="standard"
                  size="mini"
                  inputProps={{
                    ...params.inputProps,
                    style: { textAlign: 'right' },
                  }}
                />
              )}
            />
          </FlaggableBox>
          <FlaggableBox flag={flagCostPerItemDifferent}>
            <FormattedTextField
              value={curUnitPrice}
              format="dollarsAndCents"
              onChange={(e) => {
                setCurUnitPrice(e.target.value ? parseFloat(e.target.value) : 0)
              }}
              placeholder="Unit Price"
              multiline
              variant="standard"
              size="mini"
              sx={{
                '& > div': {
                  paddingTop: '2px',
                  paddingBottom: '0px',
                },
              }}
              align="right"
            />
          </FlaggableBox>
          <Box width="40px">
            <FormattedTextField
              value={curQuantity}
              format="number"
              onChange={(e) => {
                setCurQuantity(e.target.value ? parseFloat(e.target.value) : 0)
              }}
              placeholder="Quantity"
              multiline
              variant="standard"
              size="mini"
              sx={{
                '& > div': {
                  paddingTop: '2px',
                  paddingBottom: '0px',
                },
              }}
              align="right"
            />
          </Box>
          <Autocomplete
            value={curUrgency}
            onChange={(_, value) => setCurUrgency(value || '')}
            options={urgencyOptions}
            disableClearable
            placeholder="Urgency"
            slotProps={autocompleteSlotProps}
            sx={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                multiline
                variant="standard"
                size="mini"
                inputProps={{
                  ...params.inputProps,
                  style: { textAlign: 'right' },
                }}
              />
            )}
          />
          <Autocomplete
            value={curAdjustment}
            onChange={(_, value) => {
              setCurAdjustment(value || adjustmentOptions[0])
            }}
            options={adjustmentOptions}
            disableClearable
            placeholder="100%"
            slotProps={autocompleteSlotProps}
            sx={{
              width: '100%',
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                multiline
                variant="standard"
                size="mini"
                inputProps={{
                  ...params.inputProps,
                  style: { textAlign: 'right' },
                }}
              />
            )}
          />
          <TextField
            value={`$${curTotalCost.toLocaleString()}`}
            multiline
            disabled
            variant="standard"
            size="mini"
            InputProps={{
              disableUnderline: true,
              sx: {
                '& > textarea': {
                  textAlign: 'right',
                  color: 'moneyGreen.main',
                },

                '& .Mui-disabled': {
                  '-webkit-text-fill-color': 'unset',
                },
              },
            }}
          />{' '}
        </SolutionRowContainer>
      </Box>
    </ClickAwayListener>
  )
}

export default SolutionRow
