import React from 'react'
import { Box, Typography, styled } from '@mui/material'
import {
  CartesianGrid,
  Legend,
  Line,
  XAxis,
  YAxis,
  ComposedChart,
} from 'recharts'
import useCashFlowPage from './useCashFlowPage'
import { CashFlowPageProps } from './cashFlowPageTypes'
import SinglePagePrintWrapper from '../SinglePagePrintWrapper'
import {
  FilledBox,
  Header,
  OutlinedBox,
} from '../../SharedPrintCollateralComponents'

const StyledComposedChart = styled(ComposedChart)({
  fontFamily: 'Outfit!important',
  '& .recharts-cartesian-grid > .recharts-cartesian-grid-horizontal > line:nth-last-child(2)':
    {
      display: 'none!important',
    },
})

const CashFlowPage = (props: CashFlowPageProps) => {
  const presentationalProps = useCashFlowPage(props)
  const {
    cashFlowPercentage,
    minimumHoldPeriod,
    hideNetYield,
    flexibleCashFlowOutputs,
    startingAccountValue,
    uwCashOnCashYield,
    graphDomain,
    tickFormatterY,
    data,
    showCashFlowDeductions,
    millifyWithPrecision,

    pageOffset,
    coverName,
    batchStart,
    batchEnd,
  } = presentationalProps

  return (
    <SinglePagePrintWrapper
      shouldShow={pageOffset >= batchStart && pageOffset < batchEnd}
      pageNumber={pageOffset}
      coverName={coverName}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box display="flex" flexDirection="column" gap="12px">
          <Header section="Valuation" title="Cash Flow & Returns" />
          <Box display="flex" flexDirection="column" gap="8px">
            <FilledBox
              gap="4px"
              sx={{
                backgroundColor: 'green1.main',
              }}
            >
              <Typography variant="p4">Your starting account value</Typography>
              <Box display="flex" gap="8px" alignItems="baseline">
                <Typography
                  variant="h4"
                  sx={{
                    color: 'moneyGreen.main',
                    fontSize: '20px',
                    fontWeight: 600,
                    lineHeight: '28px',
                  }}
                >
                  ${startingAccountValue.toLocaleString()}
                </Typography>
                <Typography
                  variant="p6"
                  sx={{
                    color: '#7B9488',
                  }}
                >
                  Target return of 8-12% comprised of cash flow & appreciation
                </Typography>
              </Box>
            </FilledBox>
            <OutlinedBox
              gap="16px"
              sx={{
                flexDirection: 'row',
              }}
            >
              <Box display="flex" flexDirection="column" gap="4px">
                <Typography variant="p4">
                  Projected return after one year
                </Typography>
                <Box display="flex" gap="8px" alignItems="baseline">
                  <Typography
                    variant="h4"
                    sx={{
                      color: 'moneyGreen.main',
                      fontSize: '20px',
                      fontWeight: 600,
                      lineHeight: '28px',
                    }}
                  >
                    $
                    {Math.round(
                      flexibleCashFlowOutputs.totalReturn[0]
                    ).toLocaleString()}
                  </Typography>
                  <Typography
                    variant="p6"
                    sx={{
                      color: '#7B9488',
                    }}
                  >
                    10% total return
                  </Typography>
                </Box>
              </Box>
              {cashFlowPercentage ? (
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography variant="p4">
                    Cash taken after one year
                  </Typography>
                  <Box display="flex" gap="8px" alignItems="baseline">
                    <Typography
                      variant="h4"
                      sx={{
                        color: 'green4.main',
                        fontSize: '20px',
                        fontWeight: 600,
                        lineHeight: '28px',
                      }}
                    >
                      $
                      {Math.round(
                        flexibleCashFlowOutputs.cashWithdrawn[0]
                      ).toLocaleString()}
                    </Typography>
                    <Typography
                      variant="p6"
                      sx={{
                        color: '#7B9488',
                      }}
                    >
                      {(cashFlowPercentage * 100).toFixed(2)}% cash flow
                    </Typography>
                  </Box>
                </Box>
              ) : null}
            </OutlinedBox>
            <OutlinedBox gap="4px">
              <Typography variant="p4">Projected returns with Flock</Typography>
              <Box>
                <StyledComposedChart
                  width={514}
                  height={120}
                  data={data}
                  margin={{ top: 0, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="4 1 2" />
                  <XAxis
                    dataKey="name"
                    style={{ fontFamily: 'Outfit, Arial', fontSize: '14px' }}
                  />
                  <YAxis
                    width={40}
                    scale="linear"
                    interval="preserveStartEnd"
                    domain={graphDomain}
                    tickFormatter={tickFormatterY}
                    tickCount={3}
                    style={{ fontFamily: 'Outfit, Arial', fontSize: '14px' }}
                  />
                  <Legend />
                  <Line
                    legendType="none"
                    type="monotone"
                    dataKey="Total Return"
                    stroke="#03341D"
                    strokeWidth={2}
                    isAnimationActive={false}
                  />
                </StyledComposedChart>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                pb="8px"
                alignItems="center"
              >
                <Box width="100px" />
                {data.map((dataYear: any) => (
                  <Typography
                    variant="p4"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {dataYear.name}
                  </Typography>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                pb="8px"
                alignItems="center"
              >
                <Typography
                  variant="p4"
                  width="100px"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Initial Value
                </Typography>
                {data.map((dataYear: any) => (
                  <Typography
                    variant="p4"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {millifyWithPrecision(dataYear['Total Return'], 1)}
                  </Typography>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                pb="8px"
                alignItems="center"
              >
                <Typography
                  variant="p4"
                  color="gray8.main"
                  width="100px"
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Total Return
                </Typography>
                {data.map((_: any, idx: number) => (
                  <Typography
                    variant="p4"
                    width="80px"
                    sx={{ textAlign: 'right' }}
                  >
                    {millifyWithPrecision(
                      flexibleCashFlowOutputs.totalReturn[idx],
                      1
                    )}
                  </Typography>
                ))}
              </Box>
              {!hideNetYield && cashFlowPercentage ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb="8px"
                  alignItems="center"
                >
                  <Typography
                    variant="p4"
                    width="100px"
                    color="green4.main"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Cash Taken
                  </Typography>
                  {data.map((_: any, idx: number) => (
                    <Typography
                      variant="p4"
                      width="80px"
                      color="green4.main"
                      sx={{ textAlign: 'right' }}
                    >
                      {millifyWithPrecision(
                        flexibleCashFlowOutputs.cashWithdrawn[idx],
                        1
                      )}
                    </Typography>
                  ))}
                </Box>
              ) : null}
              {showCashFlowDeductions ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  pb="8px"
                  alignItems="center"
                >
                  <Typography
                    variant="p4"
                    width="100px"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Deductions
                  </Typography>
                  {data.map((_: any, idx: number) => (
                    <Typography
                      variant="p4"
                      width="80px"
                      sx={{ textAlign: 'right' }}
                    >
                      {millifyWithPrecision(
                        flexibleCashFlowOutputs.cashDeduction[idx],
                        1
                      )}
                    </Typography>
                  ))}
                </Box>
              ) : null}
            </OutlinedBox>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="2px" pb="48px">
          {minimumHoldPeriod ? (
            <Typography variant="p6" color="gray5.main">
              To benefit from the terms outlined in this valuation, it is a
              requirement to maintain your investment in the Fund for at least{' '}
              {minimumHoldPeriod} years.
            </Typography>
          ) : null}
          {uwCashOnCashYield && uwCashOnCashYield >= 0 ? (
            <Typography variant="p6" color="gray5.main">
              This model projects meeting a target annual return of 10% with
              annual cash flow. Cash flow includes your{' '}
              {Math.min(
                Math.round(uwCashOnCashYield * 1000000 - 20000) / 10000,
                4
              )}
              % Annual Cash Flow Allotment + 2% Top Up Allowance. Your capital
              account will be debited 0.10% for every 1% in cash flow you
              receive above your annual allotment. This deduction compensates
              all other clients in the Fund. Taking cash beyond 2-3% annually in
              the first 2-3 years of owning Flock may have tax impacts. Actual
              impacts are expected to be limited and generally in the form of
              capital gains tax on the amount of cash exceeding this threshold.
            </Typography>
          ) : null}
          <Typography variant="p6" color="gray5.main">
            Performance Disclaimer: In considering the target performance
            information contained herein, prospective investors should bear in
            mind that past or targeted performance is not a guarantee,
            projection or prediction and is not necessarily indicative of future
            results. There can be no assurance that the Fund will achieve
            comparable results, that targeted returns will be met or that the
            Fund will be able to implement its investment strategy and
            investment approach or achieve its investment objectives. Actual
            gross and net returns for the Fund may vary significantly from the
            targeted returns set forth herein.
          </Typography>
        </Box>
      </Box>
    </SinglePagePrintWrapper>
  )
}

export default CashFlowPage
