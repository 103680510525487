import React from 'react'
import {
  Typography,
  OpacityLink,
  Form,
  InputType,
  FileUploadFileType,
} from '@flock/flock-component-library'
import { styled } from '@mui/material/styles'
import { RouteComponentProps } from '@reach/router'
import { useSnackbar } from '@flock/shared-ui'
import { useMutation } from '@apollo/client'
import {
  AdminUploadInsuranceUpdateDocument,
  AdminUploadPropertyTaxUpdateDocument,
  AdminUploadTaxAccrualUpdateDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'

const PageContainer = styled('div')({
  minWidth: '70vw',
})

const PageTitleContainer = styled('div')({
  marginTop: '4rem',
  marginBottom: '3rem',
})

const SubHeaderContainer = styled('div')({
  marginTop: '1rem',
  marginBottom: '1rem',
  paddingLeft: '3rem',
})

const UploadContainer = styled('div')({
  width: '800px',
})

const FileUploadWrapper = styled('div')({
  width: '100%',
  maxHeight: '75vh',
  marginBottom: '5rem',
})

type UploadAndParseProps = {
  onSubmit: (result: any) => void
}

const UploadAndParseInsurance = ({ onSubmit }: UploadAndParseProps) => (
  <FileUploadWrapper>
    <Form
      onSubmit={onSubmit}
      ctaText="Parse Insurance"
      inputConfigs={[
        {
          inputName: 'desc',
          inputType: InputType.NonInput,
          props: {
            component: (
              <Typography variant="body2">
                Upload filled out Insurance template
              </Typography>
            ),
          },
        },
        {
          inputName: 'uploadedFile',
          inputType: InputType.FileUpload,
          props: {
            accept: FileUploadFileType.CSV,
            label: 'Insurance Spreadsheet',
          },
        },
      ]}
    />
  </FileUploadWrapper>
)

const UploadAndParseTaxes = ({ onSubmit }: UploadAndParseProps) => (
  <FileUploadWrapper>
    <Form
      onSubmit={onSubmit}
      ctaText="Parse Taxes"
      inputConfigs={[
        {
          inputName: 'desc',
          inputType: InputType.NonInput,
          props: {
            component: (
              <Typography variant="body2">
                Upload filled out Taxes template
              </Typography>
            ),
          },
        },
        {
          inputName: 'uploadedFile',
          inputType: InputType.FileUpload,
          props: {
            accept: FileUploadFileType.CSV,
            label: 'Taxes Spreadsheet',
          },
        },
      ]}
    />
  </FileUploadWrapper>
)

const UploadAndParseTaxAccrual = ({ onSubmit }: UploadAndParseProps) => (
  <FileUploadWrapper>
    <Form
      onSubmit={onSubmit}
      ctaText="Parse Tax Accrual"
      inputConfigs={[
        {
          inputName: 'desc',
          inputType: InputType.NonInput,
          props: {
            component: (
              <Typography variant="body2">
                Upload filled out Tax Accrual template
              </Typography>
            ),
          },
        },
        {
          inputName: 'uploadedFile',
          inputType: InputType.FileUpload,
          props: {
            accept: FileUploadFileType.CSV,
            label: 'Tax Accrual Spreadsheet',
          },
        },
      ]}
    />
  </FileUploadWrapper>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DataLakeUpdatesPage = (_: RouteComponentProps) => {
  const { notify } = useSnackbar()
  const [uploadInsurance] = useMutation(AdminUploadInsuranceUpdateDocument)
  const [uploadPropertyTax] = useMutation(AdminUploadPropertyTaxUpdateDocument)
  const [uploadTaxAccrual] = useMutation(AdminUploadTaxAccrualUpdateDocument)

  const submitInsurance = async (result: any) => {
    const { uploadedFile } = result
    const file = uploadedFile ? uploadedFile[0] : null
    if (file) {
      const uploadInsuranceInput = {
        file,
      }
      try {
        await uploadInsurance({
          variables: {
            input: uploadInsuranceInput as any,
          },
        })
        notify('Successfully uploaded insurance', 'success')
      } catch (e) {
        notify(
          'Failed to upload insurance, please check format or reach out to eng',
          'error'
        )
      }
    }
  }

  const submitPropertyTax = async (result: any) => {
    const { uploadedFile } = result
    const file = uploadedFile ? uploadedFile[0] : null
    if (file) {
      const uploadPropertyTaxInput = {
        file,
      }
      try {
        await uploadPropertyTax({
          variables: {
            input: uploadPropertyTaxInput as any,
          },
        })
        notify('Successfully uploaded property taxes', 'success')
      } catch (e) {
        notify(
          'Failed to upload property taxes, please check format or reach out to eng',
          'error'
        )
      }
    }
  }

  const submitTaxAccrual = async (result: any) => {
    const { uploadedFile } = result
    const file = uploadedFile ? uploadedFile[0] : null
    if (file) {
      const uploadTaxAccrualInput = {
        file,
      }
      try {
        await uploadTaxAccrual({
          variables: {
            input: uploadTaxAccrualInput as any,
          },
        })
        notify('Successfully uploaded tax accrual', 'success')
      } catch (e) {
        notify(
          'Failed to upload tax accrual, please check format or reach out to eng',
          'error'
        )
      }
    }
  }

  return (
    <PageContainer>
      <PageTitleContainer>
        <Typography variant="h1">Data Lake Bulk Uploads</Typography>
      </PageTitleContainer>
      <PageTitleContainer>
        <Typography variant="h2">Insurance Update</Typography>
      </PageTitleContainer>
      <SubHeaderContainer>
        <Typography variant="h3">
          Step 1: Download and fill out the Insurance Update template
        </Typography>
        <SubHeaderContainer>
          <Typography variant="body2">
            Download the&nbsp;
            <OpacityLink href="/insurance_template.csv">
              Insurance Update Template here.
            </OpacityLink>
          </Typography>
        </SubHeaderContainer>
        <br />
        <Typography variant="h3">
          Step 2: Upload the filled out template
        </Typography>
        <SubHeaderContainer>
          <UploadContainer>
            <UploadAndParseInsurance onSubmit={submitInsurance} />
          </UploadContainer>
        </SubHeaderContainer>
      </SubHeaderContainer>
      <PageTitleContainer>
        <Typography variant="h2">Property Taxes Update</Typography>
      </PageTitleContainer>
      <SubHeaderContainer>
        <Typography variant="h3">
          Step 1: Download and fill out the Property Taxes Update template
        </Typography>
        <SubHeaderContainer>
          <Typography variant="body2">
            Download the&nbsp;
            <OpacityLink href="/property_taxes_template.csv">
              Property Taxes Update Template here.
            </OpacityLink>
          </Typography>
        </SubHeaderContainer>
        <br />
        <Typography variant="h3">
          Step 2: Upload the filled out template
        </Typography>
        <SubHeaderContainer>
          <UploadContainer>
            <UploadAndParseTaxes onSubmit={submitPropertyTax} />
          </UploadContainer>
        </SubHeaderContainer>
      </SubHeaderContainer>
      <PageTitleContainer>
        <Typography variant="h2">Property Tax Accrual Backfill</Typography>
      </PageTitleContainer>
      <SubHeaderContainer>
        <Typography variant="h3">
          Step 1: Download and fill out the Property Tax Accrual Update template
        </Typography>
        <SubHeaderContainer>
          <Typography variant="body2">
            Download the&nbsp;
            <OpacityLink href="/property_tax_accrual_template.csv">
              Property Tax Accrual Update Template here.
            </OpacityLink>
          </Typography>
        </SubHeaderContainer>
        <br />
        <Typography variant="h3">
          Step 2: Upload the filled out template
        </Typography>
        <SubHeaderContainer>
          <UploadContainer>
            <UploadAndParseTaxAccrual onSubmit={submitTaxAccrual} />
          </UploadContainer>
        </SubHeaderContainer>
      </SubHeaderContainer>
    </PageContainer>
  )
}

export default DataLakeUpdatesPage
