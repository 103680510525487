import React from 'react'
import { SubHeader as FlockSubHeader } from '@flock/flock-component-library'
import {
  ArrowSquareIn,
  ArrowSquareOut,
  Briefcase,
  Clipboard,
  ClipboardText,
  UserCircle,
  Money,
  File,
} from 'phosphor-react'
import { navigate, WindowLocation } from '@reach/router'
import {
  HOME_OPS_TASKS,
  HOME_OPS_URL,
  FUND_MANAGEMENT_URL,
  INVESTOR_MANAGEMENT_URL,
  SALES_BASE_URL,
  SALES_LEAD_MANAGEMENT_URL,
  SALES_CUSTOMER_MANAGEMENT_URL,
  SALES_OUTBOUND_TASKS,
  SALES_TASKS,
  DISTRIBUTIONS_URL,
  BROKER_MANAGEMENT_URL,
  EXCHANGE_URL,
  ORDER_ONBOARDING_URL,
  NEW_CONTRIBUTIONS_URL,
  ADDRESS_SEARCH_URL,
  SALES_LEAD_DOCUMENTS_CENTER_URL,
  REPORTS_URL,
  DATA_LAKE_BASE_URL,
  DATA_LAKE_REPORTING_URL,
  DATA_LAKE_SEARCH_URL,
  HOME_OPS_CUSTOMER_URL,
  DATA_LAKE_UPDATES_URL,
} from '../constants'

type SubHeaderProps = {
  location?: WindowLocation
}

const SubHeader = (props: SubHeaderProps) => {
  const { location } = props
  const salesConfigs = [
    {
      text: 'Inbound Tasks',
      onClick: () => {
        navigate(SALES_TASKS)
      },
      ImageComponent: <ArrowSquareIn weight="bold" />,
      location: SALES_TASKS,
    },
    {
      text: 'Outbound Tasks',
      onClick: () => {
        navigate(SALES_OUTBOUND_TASKS)
      },
      ImageComponent: <ArrowSquareOut weight="bold" />,
      location: SALES_OUTBOUND_TASKS,
    },
    {
      text: 'Lead Management',
      onClick: () => {
        navigate(SALES_LEAD_MANAGEMENT_URL)
      },
      ImageComponent: <UserCircle weight="bold" />,
      location: SALES_LEAD_MANAGEMENT_URL,
    },
    {
      text: 'Customer Management',
      onClick: () => {
        navigate(SALES_CUSTOMER_MANAGEMENT_URL)
      },
      ImageComponent: <UserCircle weight="bold" />,
      location: SALES_CUSTOMER_MANAGEMENT_URL,
    },
    {
      text: 'Broker Management',
      onClick: () => {
        navigate(BROKER_MANAGEMENT_URL)
      },
      ImageComponent: <Briefcase weight="bold" />,
      location: BROKER_MANAGEMENT_URL,
    },
    {
      text: 'Lead Documents',
      onClick: () => {
        navigate(SALES_LEAD_DOCUMENTS_CENTER_URL)
      },
      ImageComponent: <File weight="bold" />,
      location: SALES_LEAD_DOCUMENTS_CENTER_URL,
    },
  ]

  const homeOpsConfigs = [
    {
      text: 'Tasks',
      onClick: () => {
        navigate(HOME_OPS_TASKS)
      },
      ImageComponent: <Clipboard weight="bold" />,
      location: HOME_OPS_TASKS,
    },
    {
      text: 'Lead Management',
      onClick: () => {
        navigate(HOME_OPS_URL)
      },
      ImageComponent: <UserCircle weight="bold" />,
      location: HOME_OPS_URL,
    },
    {
      text: 'Customer Management',
      onClick: () => {
        navigate(HOME_OPS_CUSTOMER_URL)
      },
      ImageComponent: <UserCircle weight="bold" />,
      location: HOME_OPS_CUSTOMER_URL,
    },
  ]

  const fundManagementConfigs = [
    {
      text: 'Investors',
      onClick: () => {
        navigate(INVESTOR_MANAGEMENT_URL)
      },
      ImageComponent: <UserCircle weight="bold" />,
      location: INVESTOR_MANAGEMENT_URL,
    },
    {
      text: 'Distributions',
      onClick: () => {
        navigate(DISTRIBUTIONS_URL)
      },
      ImageComponent: <Money weight="bold" />,
      location: DISTRIBUTIONS_URL,
    },
    {
      text: 'Reporting',
      onClick: () => {
        navigate(REPORTS_URL)
      },
      ImageComponent: <Money weight="bold" />,
      location: REPORTS_URL,
    },
  ]

  const orderOnboardingConfigs = [
    {
      text: 'New Contributions',
      onClick: () => {
        navigate(NEW_CONTRIBUTIONS_URL)
      },
      ImageComponent: <ClipboardText weight="bold" />,
      location: NEW_CONTRIBUTIONS_URL,
    },
    {
      text: 'Orders',
      onClick: () => {
        navigate(ORDER_ONBOARDING_URL)
      },
      ImageComponent: <ClipboardText weight="bold" />,
      location: ORDER_ONBOARDING_URL,
    },
  ]

  const addressSearchConfigs = [
    {
      text: 'Addresses',
      onClick: () => {
        navigate(ADDRESS_SEARCH_URL)
      },
      ImageComponent: <ClipboardText weight="bold" />,
      location: ADDRESS_SEARCH_URL,
    },
  ]
  const dataLakeConfigs = [
    {
      text: 'Property Search',
      onClick: () => {
        navigate(DATA_LAKE_SEARCH_URL)
      },
      ImageComponent: <ClipboardText weight="bold" />,
      location: DATA_LAKE_SEARCH_URL,
    },
    {
      text: 'Property Reporting',
      onClick: () => {
        navigate(DATA_LAKE_REPORTING_URL)
      },
      ImageComponent: <ClipboardText weight="bold" />,
      location: DATA_LAKE_REPORTING_URL,
    },
    {
      text: 'Property Updates',
      onClick: () => {
        navigate(DATA_LAKE_UPDATES_URL)
      },
      ImageComponent: <ClipboardText weight="bold" />,
      location: DATA_LAKE_UPDATES_URL,
    },
  ]

  let configs = homeOpsConfigs
  if (typeof window !== 'undefined') {
    const url = window.location.href
    if (url.includes(FUND_MANAGEMENT_URL)) {
      configs = fundManagementConfigs
    } else if (url.includes(EXCHANGE_URL)) {
      configs = orderOnboardingConfigs
    } else if (url.includes(SALES_BASE_URL)) {
      configs = salesConfigs
    } else if (url.includes(ADDRESS_SEARCH_URL)) {
      configs = addressSearchConfigs
    } else if (url.includes(DATA_LAKE_BASE_URL)) {
      configs = dataLakeConfigs
    }
  }

  let selectedTab = configs.findIndex((config) =>
    location?.href?.includes(config.location)
  )
  if (selectedTab === -1) {
    selectedTab = 0
  }

  return <FlockSubHeader selectedTab={selectedTab} menuItemConfigs={configs} />
}

SubHeader.defaultProps = {
  location: null,
}

export default SubHeader
