import {
  CircleCheckedIcon,
  CircleUncheckedIcon,
  flockTheme,
  RightArrowFilledIcon,
} from '@flock/shared-ui'
import { DeleteOutline } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { navigate } from '@reach/router'
import React from 'react'
import { SOW_HOME } from '../../constants'
import DeleteSowProjectModal from './DeleteSowProjectModal'
import { SowProjectRow } from './sowProjectsPageTypes'

export const SowProjectsPageHeaderRow = () => (
  <Box
    display="flex"
    gap="16px"
    p="16px"
    borderRadius="16px"
    width="100%"
    sx={{
      backgroundColor: flockTheme.palette.white5.main,
    }}
  >
    <Box
      minWidth="88px"
      display="flex"
      gap="4px"
      borderRadius="8px"
      alignItems="center"
      padding="8px 12px"
    >
      <Box
        width="16px"
        height="16px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircleUncheckedIcon width="16px" height="16px" />
      </Box>
      <Typography variant="c1" width="100%" textAlign="center">
        Status
      </Typography>
    </Box>

    <Box display="flex" alignItems="center" width="100%">
      <Typography variant="ctam">Address</Typography>
    </Box>

    <Box display="flex" gap="4px" alignItems="center" minWidth="152px">
      <Typography variant="ctam">Type</Typography>
      <Typography variant="p2"> | Units</Typography>
    </Box>

    <Box display="flex" gap="4px" alignItems="center" minWidth="136px">
      <Typography variant="ctam">Date of Inspection</Typography>
    </Box>

    {/* <Box display="flex" gap="4px" alignItems="center" minWidth="136px">
      <Typography variant="p2">Date Modified</Typography>
    </Box> */}
    <Box minWidth="36px" />
  </Box>
)

const SowProjectsPageRow = (props: SowProjectRow) => {
  const {
    uuid,
    status,
    formattedAddress,
    propertyType,
    numUnits,
    inspectionDate,
    deleteSOWProjectModalOpen,
    openDeleteSOWProjectModal,
    closeDeleteEventModal,
  } = props

  return (
    <Box
      display="flex"
      gap="16px"
      p="16px"
      borderRadius="16px"
      alignItems="center"
      sx={{
        backgroundColor: flockTheme.palette.white.main,
        transition: 'box-shadow 0.2s ease-in-out',

        '&:hover': {
          boxShadow: '0px 8px 24px 0px rgba(69, 73, 77, 0.16)',
        },
      }}
    >
      <Box
        minWidth="88px"
        display="flex"
        gap="4px"
        borderRadius="8px"
        alignItems="center"
        padding="8px 12px"
        sx={{
          backgroundColor: flockTheme.palette.green0.main,
          cursor: 'pointer',
        }}
        onClick={() => {
          navigate(`${SOW_HOME}/${uuid}`)
        }}
      >
        {status === 'completed' ? (
          <>
            <Box
              width="16px"
              height="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircleCheckedIcon width="16px" height="16px" />
            </Box>
            <Typography variant="c1" width="100%" textAlign="center">
              DONE
            </Typography>
          </>
        ) : (
          <>
            <Box
              width="16px"
              height="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircleUncheckedIcon width="16px" height="16px" />
            </Box>
            <Typography variant="c1" width="100%" textAlign="center">
              WIP
            </Typography>
          </>
        )}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        width="100%"
        onClick={() => {
          navigate(`${SOW_HOME}/${uuid}`)
        }}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Typography variant="ctam">{formattedAddress}</Typography>
      </Box>

      <Box
        display="flex"
        gap="4px"
        alignItems="center"
        minWidth="152px"
        onClick={() => {
          navigate(`${SOW_HOME}/${uuid}`)
        }}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Typography variant="ctam">{propertyType}</Typography>
        <Typography variant="p2">
          {numUnits > 1 && <> | {numUnits} Units</>}
        </Typography>
      </Box>

      <Box
        display="flex"
        gap="4px"
        alignItems="center"
        minWidth="136px"
        onClick={() => {
          navigate(`${SOW_HOME}/${uuid}`)
        }}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Typography variant="ctam">{inspectionDate}</Typography>
      </Box>

      {/* <Box display="flex" gap="4px" alignItems="center" minWidth="136px">
        <Typography variant="p2">{dateModified}</Typography>
      </Box> */}

      <Box
        width="36px"
        height="36px"
        onClick={() => {
          navigate(`${SOW_HOME}/${uuid}`)
        }}
        sx={{
          cursor: 'pointer',
        }}
      >
        <RightArrowFilledIcon width="36px" height="36px" />
      </Box>

      <Box
        width="24px"
        height="24px"
        onClick={() => openDeleteSOWProjectModal()}
        sx={{
          cursor: 'pointer',
        }}
      >
        <DeleteOutline />
      </Box>

      <DeleteSowProjectModal
        uuid={uuid}
        isOpen={deleteSOWProjectModalOpen}
        close={closeDeleteEventModal}
      />
    </Box>
  )
}

export default SowProjectsPageRow
