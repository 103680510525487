import { useMutation } from '@apollo/client'
import { ConfirmModal } from '@flock/flock-component-library'
import {
  AdminDeletePropertyDownDetailDocument,
  AdminGetPropertyOccupancyDataDocument,
  Core_DeletePropertyDownDetailRequestInput,
  Core_PropertyDownDetail,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import React from 'react'

type DeleteDownDetailModalParams = {
  downDetail: Core_PropertyDownDetail
  isOpen: boolean
  close: () => void
}

const DeleteDownDetailModal = (props: DeleteDownDetailModalParams) => {
  const { downDetail, isOpen, close } = props
  const { notify } = useSnackbar()

  const [deleteDownDetail, { loading }] = useMutation(
    AdminDeletePropertyDownDetailDocument
  )

  const deleteDownDetailInput: Core_DeletePropertyDownDetailRequestInput = {
    downDetailUuid: downDetail.uuid,
  }

  const onSubmit = async () => {
    try {
      await deleteDownDetail({
        variables: {
          input: deleteDownDetailInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify(
        `Successfully deleted DownDetail starting at ${downDetail.effectiveStartDate}.`,
        'success'
      )
      close()
    } catch (e) {
      notify('Failed to delete DownDetail.', 'error')
    }
  }

  return (
    <ConfirmModal
      title="Delete DownDetail"
      content={
        loading
          ? 'deleting...'
          : `Are you sure you want to delete DownDetail starting at ${downDetail.effectiveStartDate}?`
      }
      open={isOpen}
      onClose={close}
      onSubmit={onSubmit}
    />
  )
}

export default DeleteDownDetailModal
