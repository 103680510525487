import React from 'react'
import { InputType, useSnackbar } from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'
import {
  AdminGetPropertyOccupancyDataDocument,
  AdminUpdatePropertyDownDetailDocument,
  Core_PropertyDownDetail,
  Core_UpdatePropertyDownDetailRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'
import { cleanTimeFromDatetime } from '../../utils'

type UpdateDownDetailParams = {
  effectiveStartDate: string
  effectiveEndDate: string
}

type UpdateDownDetailModalParams = {
  downDetailInfo: Core_PropertyDownDetail
  isOpen: boolean
  close: () => void
}

const UpdateDownDetailModal = (props: UpdateDownDetailModalParams) => {
  const { downDetailInfo, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [updateDownDetail, { loading }] = useMutation(
    AdminUpdatePropertyDownDetailDocument
  )

  const onSubmit = async (updateDownDetailParams: UpdateDownDetailParams) => {
    const { effectiveStartDate, effectiveEndDate } = updateDownDetailParams
    const effectiveEndDateInput = effectiveEndDate
      ? cleanTimeFromDatetime(effectiveEndDate)
      : null

    const updateDownDetailInput: Core_UpdatePropertyDownDetailRequestInput = {
      downDetail: {
        uuid: downDetailInfo.uuid,
        propertyUuid: downDetailInfo.propertyUuid,
        effectiveStartDate: cleanTimeFromDatetime(effectiveStartDate),
        effectiveEndDate: effectiveEndDateInput,
      },
    }

    try {
      await updateDownDetail({
        variables: {
          input: updateDownDetailInput,
        },
        refetchQueries: [AdminGetPropertyOccupancyDataDocument],
      })
      notify('Successfully updated DownDetail.', 'success')
      handleClose()
    } catch (e) {
      notify('Failed to update DownDetail.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Update DownDetail"
      inputConfigs={[
        {
          inputName: 'effectiveStartDate',
          inputType: InputType.DatePicker,
          renderOn: 'true',
          required: true,
          props: {
            label: 'EffectiveStartDate',
            fieldProps: {
              fullWidth: true,
            },
            defaultValue: downDetailInfo.effectiveStartDate,
          },
        },
        {
          inputName: 'effectiveEndDate',
          inputType: InputType.DatePicker,
          renderOn: 'true',
          props: {
            label: 'EffectiveEndDate',
            fieldProps: {
              fullWidth: true,
            },
            defaultValue: downDetailInfo.effectiveEndDate,
          },
        },
      ]}
    />
  )
}

export default UpdateDownDetailModal
