import { gql, useMutation, useQuery } from '@apollo/client'
import {
  AdminGetAllCostbooksDocument,
  AdminSearchSowProjectsDocument,
  AdminUpdateCostbookFileDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import { debounce } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import {
  SowProjectsPageProps,
  SowProjectsPagePresentationalProps,
  DisplayedSowProject,
  UploadCostbookParams,
} from './sowProjectsPageTypes'

export const UPDATE_COSTBOOK_FILE = gql`
  mutation AdminUpdateCostbookFile(
    $updateCostbookFileInput: UpdateCostbookFileInput!
  ) {
    updateCostbookFile(input: $updateCostbookFileInput)
  }
`

const useSowProjectsPage: (
  props: SowProjectsPageProps
) => SowProjectsPagePresentationalProps = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [sowProjects, setSowProjects] = useState<DisplayedSowProject[]>([])
  const [loading, setLoading] = useState(false)
  const [deleteSOWProjectModalOpen, setDeleteSOWProjectModalOpen] =
    useState(false)
  const [uploadCostbookModalOpen, setUploadCostbookModalOpen] = useState(false)
  const [costbookOptions, setCostbookOptions] = useState<
    {
      label: string
      value: string
    }[]
  >([])
  const [costbookUpdateLoading, setCostbookUpdateLoading] = useState(false)

  const { notify } = useSnackbar()

  const openDeleteSOWProjectModal = () => {
    setDeleteSOWProjectModalOpen(true)
  }

  const closeDeleteEventModal = () => {
    setDeleteSOWProjectModalOpen(false)
  }

  const { refetch } = useQuery(AdminSearchSowProjectsDocument, {
    variables: {
      input: {
        searchString: '',
      },
    },
  })

  const [updateCostbookFile] = useMutation(AdminUpdateCostbookFileDocument)

  useQuery(AdminGetAllCostbooksDocument, {
    onCompleted: (data) => {
      const cbOptions = data?.getAllCostbooks?.costbooks?.map((cb) => ({
        label: cb?.name as string,
        value: cb?.uuid as string,
      }))
      if (cbOptions) {
        setCostbookOptions(cbOptions)
      }
    },
  })

  const onChange = async (value: string) => {
    setLoading(true)
    try {
      const searchResp = await refetch({
        input: {
          searchString: value,
        },
      })

      const searchResults =
        searchResp.data!.searchSOWProjects!.searchProjectResults!.map(
          (result) => {
            let displayedPropertyType = result!.address?.propertyType
            if (!displayedPropertyType) {
              displayedPropertyType = result!.address?.units?.length
                ? 'Multifamily'
                : 'Single family'
            }
            return {
              uuid: result!.uuid,
              status: result!.projectStatus as string,
              formattedAddress: result!.address?.formattedAddress || '',
              propertyType: displayedPropertyType,
              numUnits: result!.address?.units?.length || 0,
              inspectionDate:
                new Date(result!.inspectionDate).toLocaleDateString() || '-',
              dateModified: '-',
            }
          }
        )

      setSowProjects(searchResults)
    } catch (e) {
      notify('Error fetching search results', 'error')
    }
    setLoading(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchString = useCallback(debounce(onChange, 1000), [])

  useEffect(() => {
    debounceSetSearchString(searchQuery)
  }, [searchQuery, debounceSetSearchString])

  const onUploadCostbook = async (params: UploadCostbookParams) => {
    setCostbookUpdateLoading(true)

    const { costbookUuid, uploadedFile } = params
    const file = uploadedFile[0]
    const updateCostbookFileInput = {
      uuid: costbookUuid,
      file,
    }

    try {
      await updateCostbookFile({
        variables: {
          updateCostbookFileInput,
        },
      })
      notify('Costbook updated successfully', 'success')
      setUploadCostbookModalOpen(false)
    } catch (e) {
      console.log(e)
      notify('Failed to update costbook', 'error')
    }
    setCostbookUpdateLoading(false)
  }

  return {
    searchQuery,
    setSearchQuery,
    sowProjects,
    loading,
    deleteSOWProjectModalOpen,
    openDeleteSOWProjectModal,
    closeDeleteEventModal,
    uploadCostbookModalOpen,
    setUploadCostbookModalOpen,
    onUploadCostbook,
    costbookOptions,
    costbookUpdateLoading,
  }
}

export default useSowProjectsPage
