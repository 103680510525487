import React, { useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import {
  Typography,
  LoadingCard,
  flockTheme,
  PopoverMenu,
  useSnackbar,
  OpacityLink,
  DataTable,
  Button,
} from '@flock/flock-component-library'
import { styled } from '@mui/material/styles'

import zipToTz from 'zip-to-tz'

import { RouteComponentProps, useParams } from '@reach/router'
import { startCase } from 'lodash'

import { CircularProgress, Divider, Grid, ThemeProvider } from '@mui/material'
import { formatAddressString, formatCityStateZip } from '@flock/utils'
import {
  Core_ValuationType,
  Core_ValuationV1,
  AdminGetLeadDocument,
  AdminGetAddressPhotoDownloadUrlDocument,
  AdminGetValuationDocument,
  AdminHomeOpsGetValuationV1Document,
  AdminGetOperatorDocument,
  AdminGetAddressDocumentDownloadUrlDocument,
  Core_Valuation,
  Core_AddressPhoto,
  Core_AddressDocument,
  AdminGetBrokerDocument,
  Core_ValidatedAddress,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ValuationRangeOverrideModal from './ValuationRangeOverrideModal'
import ValuationModal from './ValuationModal'
import {
  BROKER_MANAGEMENT_URL,
  FLOCK_LANDING_URL,
  HOME_OPS_URL,
} from '../../constants'
import EquityOverrideModal from './EquityOverrideModal'
import LeadReassignmentModal from './LeadReassignmentModal'
import RequestContributionAgreementModal from '../TaskManagement/RequestContributionAgreementModal'
import {
  addressPhotoColumns,
  addressPhotosRenderer,
  addressDocumentColumns,
  addressDocumentsRenderer,
} from '../FundAssets/IndividualFundAssetPage'
import UploadAddressPhotoModal from '../FundAssets/UploadAddressPhotoModal'
import DataMapTable from './DataMapTable'
import DataMapSection from './DataMapSection'
import { prettyPrintJsonKeys } from '../../utils'
import EditLeadAnswersModal from './EditLeadAnswersModal'
import EditLeadOverridesModal from './EditLeadOverridesModal'
import UploadAddressDocumentModal from '../FundAssets/UploadAddressDocumentModal'
import AddLeaseAndTenantInfoModal from './AddLeaseAndTenantInfoModal'
import EditInspectionDateModal from './EditInspectionDateModal'
import LeadDocumentsSection from '../LeadManagementComponents/LeadDocumentsSection'
import AddToMqlAutomationModal from './AddToMqlAutomationModal'
import ValuationTaskModal from './ValuationTaskModal'
import LeadEventsSection from '../LeadManagementComponents/LeadEventsSection'
import { getHoldingPeriod } from '../../../../utils/src/money/flexibleCashFlowCalculator'

// TODO: refactor these into shared list of answers that is shared with FlowData struct in flock-landing-fe
const propertyDetailAnswers = [
  'additionalInfo',
  'bathCount',
  'bedCount',
  'halfBathCount',
  'basement',
  'hasUnit',
  'unitNumber',
  'squareFootage',
  'propertyType',
  'multifamilyInfo',
  'prefillSquareFootage',
  'prefillBedCount',
  'prefillBathCount',
  'prefillHalfBathCount',
  'prefillBasement',
  'prefillYearBuilt',
  'prefillBuildingQualityScore',
  'prefillBuildingConditionScore',
  'hasHOA',
  'monthlyHOAFee',
  'hasPool',
  'hasSolarPanels',
  'structuralIssues',
  'upgradedRoof',
  'upgradedHVAC',
  'upgradedWaterHeater',
  'upgradedAppliances',
  'defectStructural',
  'defectElectrical',
  'defectFloodZone',
  'defectFireDamage',
  'defectSepticWellWater',
  'defectUnpermittedAdditions',
  'propertyCondition',
  'willReceiveOfferAutomation',
]

const offerPageOverrides = [
  'waiveOnboardingFee',
  'feeOverride',
  'leadStatusOverride',
  'leadNameOverride',
  'addressDisplayOverride',
  'cityStateZipDisplayOverride',
  'yearsToProject',
  'capexCosts',
  'remodelCosts',
  'offerDisclaimer',
  'cashTakeout',
  'rentReduction',
  'daysInRemodelDeduction',
  'closingCosts',
  'brokerCommission',
  'portfolioName',
  'portfolioAssetCount',
  'portfolioDoorCount',
  'portfolioCityState',
  'portfolioSFRDoors',
  'portfolioMultifamilyDoors',
  'overrideNetYield',
  'netYieldOverride',
  'overrideHoldingPeriod',
  'holdingPeriodOverride',
  'showCashFlowDeductions',
]

const multifamilyPropertyDetailAnswers = [
  'additionalInfo',
  'propertyType',
  'prefillSquareFootage',
  'prefillBedCount',
  'prefillBathCount',
  'prefillHalfBathCount',
  'prefillBasement',
  'prefillYearBuilt',
  'prefillBuildingQualityScore',
  'prefillBuildingConditionScore',
  'hasPool',
  'hasSolarPanels',
  'structuralIssues',
  'upgradedRoof',
  'upgradedHVAC',
  'upgradedWaterHeater',
  'upgradedAppliances',
  'defectStructural',
  'defectElectrical',
  'defectFloodZone',
  'defectFireDamage',
  'defectSepticWellWater',
  'defectUnpermittedAdditions',
]

const tenantOwnershipAnswers = [
  'hasPropertyManager',
  'hasTenant',
  'isOwner',
  'rentAmount',
  'leaseEnd',
  'hasMortgage',
  'consideringSelling',
  'timeline',
  'mortgageAmount',
  'ownsWholeBuilding',
  'investorAccreditation',
]

const multifamilyTenantOwnershipAnswers = [
  'hasPropertyManager',
  'isOwner',
  'hasMortgage',
  'consideringSelling',
  'timeline',
  'mortgageAmount',
  'ownsWholeBuilding',
  'investorAccreditation',
]

const miscellaneousAnswers = [
  'attribution',
  'attributionDetail',
  'mlsNumber',
  'brokerage',
  'isValidMarket',
  'dstLead',
  'utmSource',
  'utmCampaign',
  'utmMedium',
  'reitFit',
  'customerUwCashOnCashYield',
  'holdingPeriod',
]

const Heading = styled('div')({
  marginTop: '4rem',
  marginBottom: '1rem',
})

const tableOptions = {
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  elevation: 0,
}

export const GET_VALUATION_V1 = gql`
  fragment ValuationTrailFields on Core_ValuationTrailNode {
    name
    description
    value
  }
  query AdminHomeOpsGetValuationV1($input: Core_GetValuationV1RequestInput!) {
    getValuationV1(input: $input) {
      valuation {
        uuid
        type
        finalOfferPrice
        expiresAt
        salesApproved
        inputs {
          offerPrice {
            housecanaryCondition
            comps {
              source
              similarityScore
              url
              avmPrice
              squareFootage
              estimatedRemodelCost
              address
              beds
              baths
              bathsDouble
              halfBaths
              yearBuild
              soldDate
              notes
            }
            housecanaryAdjustedAvmPrice
            notes
            selfReportedValue
            useCma
          }
          remodelCost {
            score
            cost
            daysInRemodel
            daysInRemodelDeduction
            sowCost
            notes
          }
          marketRent {
            housecanaryProjectedRent
            comps {
              source
              marketRent
              rentalDate
              squareFootage
              url
              address
              notes
            }
            analystProjectedRent
            notes
            currentlyOccupied
            currentRent
            monthsRemainingOnLease
            undergoingEviction
          }
          otherCosts {
            acquisition
            miscellaneous
            hoa
            propertyTaxes
            notes
            addressNearbyHomeHoa
            urlNearbyHomeHoa
            hoaExists
          }
        }
        outputs {
          impliedYieldMinimums
          msaLevelMinimums
          finalOfferPrice
          netYieldAdjustedOfferPrice
          grossYieldOnAdjustedOfferPrice
          grossYield
          capRate
          netYield
          noiMarginAverage
          pass
        }
        property {
          sqft
          yearBuilt
        }
        leadUuid
        lead {
          answers
          fullName
          address {
            street
            unit
            city
            state
            zipcode
          }
        }
        rejectionReason
        operatorUuid
        valuationTrail {
          ...ValuationTrailFields
          nodes {
            ...ValuationTrailFields
            nodes {
              ...ValuationTrailFields
              nodes {
                ...ValuationTrailFields
                nodes {
                  ...ValuationTrailFields
                  nodes {
                    ...ValuationTrailFields
                    nodes {
                      ...ValuationTrailFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IndividualLeadPage = (_: RouteComponentProps) => {
  const { leadUuid } = useParams()
  const { notify } = useSnackbar()
  const [addressPhotoUuid, setAddressPhotoUuid] = useState('')
  const [addressDocumentUuid, setAddressDocumentUuid] = useState('')
  const [answersModalOpen, setAnswersModalOpen] = useState(false)
  const [overridesModalOpen, setOverridesModalOpen] = useState(false)
  const [inspectionDateModalOpen, setInspectionDateModalOpen] = useState(false)
  const [leaseAndTenantInfoModalOpen, setLeaseAndTenantInfoModalOpen] =
    useState(false)
  const [leaseAndTenantInfoModalAddress, setLeaseAndTenantInfoModalAddress] =
    useState<Core_ValidatedAddress>()
  const [
    showValuationRequiresSalesApproval,
    setShowValuationRequiresSalesApproval,
  ] = useState(false)

  const { loading: leadLoading, data: leadData } = useQuery(
    AdminGetLeadDocument,
    {
      variables: {
        input: {
          leadUuid,
        },
      },
      onError: () => {
        notify('Failed to get lead data', 'error')
      },
    }
  )

  const { refetch: refetchPhoto } = useQuery(
    AdminGetAddressPhotoDownloadUrlDocument,
    {
      skip: true,
    }
  )

  const { refetch: refetchDocument } = useQuery(
    AdminGetAddressDocumentDownloadUrlDocument,
    {
      skip: true,
    }
  )

  const { data: internalValuation } = useQuery(
    AdminHomeOpsGetValuationV1Document,
    {
      variables: {
        input: {
          leadUuid,
        },
      },
      onCompleted: () => {
        const internalValuationV1Details: Core_ValuationV1 = internalValuation
          ?.getValuationV1?.valuation as Core_ValuationV1
        if (
          internalValuationV1Details?.type ===
            Core_ValuationType.ValuationTypeFinal &&
          internalValuationV1Details.finalOfferPrice &&
          !internalValuationV1Details.salesApproved
        ) {
          setShowValuationRequiresSalesApproval(true)
        }
      },
    }
  )

  const { loading: valuationLoading, data: valuationData } = useQuery(
    AdminGetValuationDocument,
    {
      variables: {
        input: {
          leadUuid,
        },
      },
    }
  )

  const { loading: operatorLoading, data: operatorData } = useQuery(
    AdminGetOperatorDocument,
    {
      variables: {
        input: { operatorUuid: leadData?.lead?.lead?.customer?.operatorUuid },
      },
      onError: () => {
        notify('Failed to get operator', 'error')
      },
      skip: leadLoading || !leadData?.lead?.lead?.customer?.operatorUuid,
    }
  )

  const { data: ownOperatorData } = useQuery(AdminGetOperatorDocument, {
    variables: {
      input: {},
    },
    onError: () => {
      notify('Failed to get admin operator data', 'error')
    },
  })

  const downloadAddressPhoto = async () => {
    try {
      const urlData = await refetchPhoto({
        addressPhotoUuid: {
          addressPhotoUuid,
        },
      })
      window.open(
        urlData?.data?.addressPhotoPresignedUrl?.presignedUrl as string,
        '_blank'
      )
    } catch (e) {
      notify(
        'An error while downloading the photo. Please refresh or try again.',
        'error'
      )
    }
  }

  const downloadAddressDocument = async () => {
    try {
      const urlData = await refetchDocument({
        addressDocumentUuid: {
          addressDocumentUuid,
        },
      })
      window.open(
        urlData?.data?.addressDocumentPresignedUrl?.presignedUrl as string,
        '_blank'
      )
    } catch (e) {
      notify(
        'An error while downloading the document. Please refresh or try again.',
        'error'
      )
    }
  }

  useEffect(() => {
    if (addressPhotoUuid !== '') {
      downloadAddressPhoto()
    }
    if (addressDocumentUuid !== '') {
      downloadAddressDocument()
    }
  }, [addressPhotoUuid, addressDocumentUuid])

  const { loading: brokerLoading, data: brokerData } = useQuery(
    AdminGetBrokerDocument,
    {
      skip: leadLoading || !leadData?.lead?.lead?.brokerUuid,
      variables: {
        input: { brokerUuid: leadData?.lead?.lead?.brokerUuid },
      },
      onError: () => {
        notify('Failed to get broker', 'error')
      },
    }
  )

  const addressPhotoOptions = {
    ...tableOptions,
    sortOrder: {
      name: 'takenDate',
      direction: 'desc',
    },
    customRowRender: addressPhotosRenderer(setAddressPhotoUuid),
  }

  const addressDocumentOptions = {
    ...tableOptions,
    customRowRender: addressDocumentsRenderer(setAddressDocumentUuid),
  }

  const valuation = valuationData?.valuation?.valuation as Core_Valuation
  let addressPhotos = leadData?.lead?.lead?.address?.photos || []
  let addressDocuments = leadData?.lead?.lead?.address?.documents || []
  const addressUnits = leadData?.lead?.lead?.address?.units || []
  if (addressUnits?.length > 0) {
    addressUnits?.forEach((unit: any) => {
      addressPhotos = [...addressPhotos, ...unit?.photos]
      addressDocuments = [...addressDocuments, ...unit?.documents]
    })
  }

  let lead: any
  let leadAnswersMap: { [x: string]: any } = {}
  let overridesMap: { [x: string]: any } = {}
  let inspectionDateFormatted = null
  let inspectionDate = null
  if (!leadLoading) {
    lead = leadData?.lead?.lead || {}
    if (lead?.overrides) {
      overridesMap = JSON.parse(lead.overrides) || {}
    }
    if (lead?.answers) {
      leadAnswersMap = JSON.parse(lead.answers)
      const valuationDetails = JSON.parse(valuation?.valuationDetails || `{}`)
      if (valuationDetails?.outputs?.uwCashOnCashYield) {
        leadAnswersMap.customerUwCashOnCashYield =
          overridesMap?.netYieldOverride ||
          valuationDetails?.outputs?.uwCashOnCashYield
            ?.customerUwCashOnCashYield
        leadAnswersMap.holdingPeriod =
          overridesMap?.holdingPeriodOverride ||
          getHoldingPeriod(
            overridesMap?.netYieldOverride ||
              leadAnswersMap.customerUwCashOnCashYield
          )
      }
    }
    // because we cannot be certain about this value for old leads and even current leads due to sendgrid flakiness/current implementation
    // only show if we actually have the value. Mainly used for admin created leads anyways.
    if (
      leadAnswersMap.isInMqlAutomation !== undefined &&
      leadAnswersMap.isInMqlAutomation !== null
    ) {
      if (!miscellaneousAnswers.includes('isInMqlAutomation')) {
        miscellaneousAnswers.push('isInMqlAutomation')
      }
    }
    if (
      lead?.inspectionDate &&
      new Date(lead.inspectionDate).getFullYear() > 2000
    ) {
      inspectionDate = new Date(lead.inspectionDate)
      const localTime = inspectionDate?.toLocaleString(undefined, {
        timeZoneName: 'short',
      })
      let leadTimeZone = ''
      try {
        leadTimeZone = zipToTz(lead?.address?.zipcode) || ''
      } catch (e) {
        console.error(e)
      }
      const formattedTime = inspectionDate?.toLocaleString(undefined, {
        timeZoneName: 'short',
        timeZone: leadTimeZone,
      })
      inspectionDateFormatted = `${formattedTime} (${localTime})`
    }
  }

  const isMultifamily = leadAnswersMap.unitDetails?.length > 0
  const propertyAnswers = isMultifamily
    ? multifamilyPropertyDetailAnswers
    : propertyDetailAnswers
  const tenantAnswers = isMultifamily
    ? multifamilyTenantOwnershipAnswers
    : tenantOwnershipAnswers

  if (leadLoading || valuationLoading) {
    return <LoadingCard text="Loading details..." />
  }
  const actions = [
    <LeadReassignmentModal lead={lead} />,
    <ValuationRangeOverrideModal lead={lead} valuation={valuation} />,
    <EquityOverrideModal lead={lead} />,
    <ValuationModal lead={lead} valuation={valuation as Core_Valuation} />,
    <ValuationTaskModal
      leadUuid={leadUuid}
      operatorUuid={ownOperatorData?.operator?.operator?.uuid as string}
    />,
    <RequestContributionAgreementModal lead={lead} />,
    <AddToMqlAutomationModal lead={lead} />,
  ]

  const links = [
    {
      text: 'Open Offer Page',
      onClick: () => {
        if (lead.source === 'dst_lead') {
          window.open(
            `${FLOCK_LANDING_URL}/property-estimate/1031/${leadUuid}`,
            '_blank'
          )
        }
        window.open(
          `${FLOCK_LANDING_URL}/property-estimate/${leadUuid}`,
          '_blank'
        )
      },
    },
    {
      text: 'Copy Offer Page Link',
      onClick: () => {
        navigator.clipboard.writeText(
          `${FLOCK_LANDING_URL}/property-estimate/${leadUuid}`
        )
        notify('Offer page copied to Clipboard')
      },
    },
    {
      text: 'View Hotjar Activity',
      onClick: () => {
        const hotjarUrl = `https://insights.hotjar.com/sites/2547595/playbacks/list?filters=%7B%22AND%22:%5B%7B%22DAYS_AGO%22:%7B%22created%22:0%7D%7D,%7B%22CONTAINS%22:%7B%22all_page_paths%22:%22${leadUuid}%22%7D%7D%5D%7D`
        window.open(hotjarUrl, '_blank')
      },
    },
    {
      text: 'Open Slack Thread',
      onClick: () => {
        window.open(lead.slackThreadUrl, '_blank')
      },
    },
    {
      text: 'FCF Val Tool',
      onClick: () => {
        window.open(`${HOME_OPS_URL}/${leadUuid}/valuationsV1`, '_blank')
      },
    },
  ]

  return (
    <>
      <Heading sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h1">Lead</Typography>
        <div>
          <PopoverMenu
            text="Links"
            buttonProps={{
              variant: 'outlined',
              sx: {
                marginRight: '1rem',
              },
            }}
            popoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }}
            actions={links}
          />
          <PopoverMenu
            text="Actions"
            buttonProps={{
              variant: 'contained',
              'data-cy': 'actionsButton',
            }}
            popoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }}
            actions={actions}
          />
        </div>
      </Heading>
      {leadLoading ? (
        <LoadingCard text="" />
      ) : (
        <ThemeProvider theme={flockTheme}>
          <Typography variant="body1">{lead.fullName}</Typography>
          <Typography variant="body1">{lead.email}</Typography>
          <Typography variant="body1">{lead.phoneNumber}</Typography>
          <Typography variant="body1">
            {lead.address ? formatAddressString(lead.address) : ''}
          </Typography>
          <Typography variant="body1">
            {formatCityStateZip(lead.address)}
          </Typography>
          <Typography variant="body1">
            <b>Source:</b> {startCase(lead.source)}
          </Typography>
          <Typography variant="body1">
            <b>Assignee:</b>
            {operatorLoading
              ? ''
              : ` ${
                  operatorData?.operator?.operator?.fullName || 'Unassigned'
                }`}
          </Typography>
          <Typography variant="body1">
            <b>Transaction Type:</b> {startCase(lead.transactionType)}
          </Typography>
          <Typography variant="body1">
            {brokerLoading || !brokerData ? (
              ''
            ) : (
              <OpacityLink
                href={`${BROKER_MANAGEMENT_URL}/${
                  brokerData?.broker?.broker?.uuid as string
                }`}
                target="_blank"
              >
                <b>Agent / Broker:</b>{' '}
                {brokerData?.broker?.broker?.firstName as string}{' '}
                {brokerData?.broker?.broker?.lastName as string}
              </OpacityLink>
            )}
          </Typography>
          {lead.address && (
            <Typography>
              <b>Inspection Date:</b> {inspectionDateFormatted}{' '}
              <OpacityLink onClick={() => setInspectionDateModalOpen(true)}>
                Update
              </OpacityLink>
            </Typography>
          )}
          {showValuationRequiresSalesApproval && (
            <Typography variant="body1" color="error">
              Final Offer Price has not been pushed to the Final Offer Page.
              <br />
              Sales must approve the valuation in the valuation tool until it
              updates.
            </Typography>
          )}

          <DataMapSection
            title="Answers"
            actionText="Edit"
            onAction={() => setAnswersModalOpen(true)}
          >
            <DataMapTable fields={propertyAnswers} dataMap={leadAnswersMap} />
          </DataMapSection>

          {isMultifamily && (
            <>
              <DataMapSection title="Unit Details">
                {leadAnswersMap.unitDetails.map((details: any, idx: number) => {
                  // order unit details
                  const unitDetailsOrder = {
                    name: null,
                  }
                  const orderedDetails = Object.assign(
                    unitDetailsOrder,
                    details
                  )
                  return (
                    <>
                      <Grid item xs={6}>
                        <Typography
                          variant="h3"
                          style={{ marginBottom: '1rem' }}
                        >
                          {startCase(orderedDetails?.name)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="h3"
                          style={{
                            marginBottom: '1rem',
                            textAlign: 'right',
                          }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setLeaseAndTenantInfoModalOpen(true)
                              setLeaseAndTenantInfoModalAddress(
                                lead?.address?.units[idx]
                              )
                            }}
                          >
                            Add Lease / Tenant Info
                          </Button>
                        </Typography>
                      </Grid>
                      <DataMapTable
                        fields={Object.keys(orderedDetails)}
                        dataMap={orderedDetails}
                      />
                      <div style={{ marginBottom: '5rem' }} />
                    </>
                  )
                })}
              </DataMapSection>
            </>
          )}

          <DataMapSection
            title="Overrides"
            actionText="Edit"
            onAction={() => setOverridesModalOpen(true)}
          >
            <DataMapTable fields={offerPageOverrides} dataMap={overridesMap} />
          </DataMapSection>
          <DataMapSection
            title="Tenant / Ownership Details"
            actionText={!isMultifamily ? 'Add Lease / Tenant Info' : undefined}
            onAction={() => {
              setLeaseAndTenantInfoModalOpen(true)
              setLeaseAndTenantInfoModalAddress(lead?.address)
            }}
          >
            {tenantAnswers.map((key) => (
              <Grid item xs={4}>
                <Typography variant="h4">{prettyPrintJsonKeys(key)}</Typography>
                <Typography variant="body1">
                  {leadAnswersMap[key] || leadAnswersMap[key] === false
                    ? leadAnswersMap[key].toString()
                    : '-'}
                </Typography>
              </Grid>
            ))}
          </DataMapSection>
          <DataMapSection title="Miscellaneous Details">
            {miscellaneousAnswers.map((key) => (
              <Grid item xs={4}>
                <Typography variant="h4">{prettyPrintJsonKeys(key)}</Typography>
                <Typography variant="body1">
                  {leadAnswersMap[key] || leadAnswersMap[key] === false
                    ? leadAnswersMap[key].toString()
                    : '-'}
                </Typography>
              </Grid>
            ))}
          </DataMapSection>
          <Divider />
          <Typography
            variant="h3"
            style={{ marginTop: '2rem', marginBottom: '1rem' }}
          >
            Address Photos
          </Typography>
          <Grid item xs={12}>
            <>
              <UploadAddressPhotoModal
                addressUuid={leadData?.lead?.lead?.address?.uuid as string}
              />
            </>
            {leadLoading ? (
              <CircularProgress size={32} />
            ) : (
              <DataTable
                title=""
                data={addressPhotos as Core_AddressPhoto[]}
                columns={addressPhotoColumns}
                options={addressPhotoOptions as any}
              />
            )}
          </Grid>
          <Typography
            variant="h3"
            style={{ marginTop: '2rem', marginBottom: '1rem' }}
          >
            Address Documents
          </Typography>
          <Grid item xs={12}>
            <>
              <UploadAddressDocumentModal addressUuid={lead.address?.uuid} />
            </>
            {leadLoading ? (
              <CircularProgress size={32} />
            ) : (
              <DataTable
                title=""
                data={addressDocuments as Core_AddressDocument[]}
                columns={addressDocumentColumns}
                options={addressDocumentOptions as any}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <LeadDocumentsSection
              lead={lead}
              leadUuid={leadUuid}
              overrides={(overridesMap as any) || {}}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ paddingTop: '64px', paddingBottom: '256px' }}
          >
            <LeadEventsSection leadUuid={leadUuid} />
          </Grid>
        </ThemeProvider>
      )}
      <EditLeadAnswersModal
        open={answersModalOpen}
        onClose={() => setAnswersModalOpen(false)}
        leadUuid={leadUuid}
        answers={leadAnswersMap}
        transactionType={lead.transactionType}
      />
      <EditLeadOverridesModal
        open={overridesModalOpen}
        onClose={() => setOverridesModalOpen(false)}
        leadUuid={leadUuid}
        overrides={overridesMap}
      />
      <AddLeaseAndTenantInfoModal
        address={leaseAndTenantInfoModalAddress as Core_ValidatedAddress}
        open={leaseAndTenantInfoModalOpen}
        onClose={() => setLeaseAndTenantInfoModalOpen(false)}
      />
      <EditInspectionDateModal
        leadUuid={leadUuid}
        open={inspectionDateModalOpen}
        inspectionDate={inspectionDate}
        onClose={() => setInspectionDateModalOpen(false)}
        zipcode={lead?.address?.zipcode!}
      />
    </>
  )
}

export default IndividualLeadPage
