import { WindowLocation } from '@reach/router'
import { navigate } from 'gatsby'
import _ from 'lodash'
import { PDFDocument, PDFPage } from 'pdf-lib'

import {
  FUND_MANAGEMENT_URL,
  HOME_OPS_BASE_URL,
  HOME_OPS_TASKS,
  HOME_OPS_URL,
  INVESTOR_MANAGEMENT_URL,
  ORDER_ONBOARDING_URL,
  SALES_BASE_URL,
  SALES_TASKS,
  DATA_LAKE_SEARCH_URL,
  INFRA_URL,
} from './constants'

export const onLogoClick = () => {
  navigate(HOME_OPS_URL)
}

const isSelected = (highlightUrl: string, location?: WindowLocation) =>
  (location?.href && location?.href?.includes(highlightUrl)) || false

export const getHeaderConfigs = (
  logout: () => void,
  location?: WindowLocation
) => [
  {
    text: 'Infra',
    onClick: () => {
      navigate(INFRA_URL)
    },
    selected: isSelected(INFRA_URL, location),
  },
  {
    text: 'Sales',
    onClick: () => {
      navigate(SALES_TASKS)
    },
    selected: isSelected(SALES_BASE_URL, location),
  },
  {
    text: 'Home Ops',
    onClick: () => {
      navigate(HOME_OPS_TASKS)
    },
    selected: isSelected(HOME_OPS_BASE_URL, location),
  },
  {
    text: 'Exchange',
    onClick: () => {
      navigate(ORDER_ONBOARDING_URL)
    },
    selected: isSelected(ORDER_ONBOARDING_URL, location),
  },
  {
    text: 'Fund Management',
    onClick: () => {
      navigate(INVESTOR_MANAGEMENT_URL)
    },
    selected: isSelected(FUND_MANAGEMENT_URL, location),
  },
  {
    text: 'Data Lake',
    onClick: () => {
      navigate(DATA_LAKE_SEARCH_URL)
    },
    selected: isSelected(DATA_LAKE_SEARCH_URL, location),
  },
  {
    text: 'Log Out',
    onClick: () => {
      logout()
    },
    selected: false,
  },
]

export const snakeToTitleCase = (text: string) => {
  if (!text) {
    return ''
  }
  const lowercaseText = text.toLowerCase().replace(/_/g, ' ')
  return lowercaseText.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}

export const prettyPrintJsonKeys = (key: string) => _.startCase(key)

export const cleanTimeFromDatetime = (date: string | undefined) => {
  if (date == null) {
    return undefined
  }
  const localDatetime = new Date(date)

  const utcDate = new Date(
    Date.UTC(
      localDatetime.getUTCFullYear(),
      localDatetime.getUTCMonth(),
      localDatetime.getUTCDate(),
      12,
      0,
      0
    )
  )
  return utcDate.toUTCString()
}

export async function mergePdfs(
  pdfsToMerge: ArrayBuffer[]
): Promise<ArrayBufferLike> {
  const mergedPdf: PDFDocument = await PDFDocument.create()

  const createInnerPromise = async (
    arrayBuffer: ArrayBuffer
  ): Promise<PDFPage[]> => {
    const pdf: PDFDocument = await PDFDocument.load(arrayBuffer)
    return mergedPdf.copyPages(pdf, pdf.getPageIndices())
  }

  const outerPromise: Promise<PDFPage[]>[] = pdfsToMerge.map((arrayBuffer) => {
    const innerPromise: Promise<PDFPage[]> = createInnerPromise(arrayBuffer)
    return innerPromise
  })

  const resultOuterPromise: PDFPage[][] = await Promise.all(outerPromise)

  resultOuterPromise.forEach((pageArray: PDFPage[]) => {
    pageArray.forEach((page: PDFPage) => {
      mergedPdf.addPage(page)
    })
  })

  return (await mergedPdf.save()).buffer
}

export const fetchImageToLocal = async (imageUrl: string) => {
  if (!imageUrl || imageUrl.length === 0) return ''
  let localUrl = ''
  try {
    const response = await fetch(imageUrl)

    if (!response.ok) {
      throw new Error(`Image fetch failed with status: ${response.status}`)
    }

    const blob = await response.blob()
    localUrl = URL.createObjectURL(blob)
  } catch (error) {
    console.error('Error fetching pdf image:', error)
  }

  return localUrl
}
