import { useMutation } from '@apollo/client'
import {
  AdminDeleteScopeOfWorkProjectDocument,
  AdminSearchSowProjectsDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { AnyInputConfig, InputType, useSnackbar } from '@flock/shared-ui'
import { Typography } from '@mui/material'
import React from 'react'
import GridFormModal from '../shared/GridFormModal'

type DeleteSowProjectModalParams = {
  uuid: string
  isOpen: boolean
  close: () => void
}

const DeleteSowProjectModal = (props: DeleteSowProjectModalParams) => {
  const { uuid, isOpen, close } = props
  const { notify } = useSnackbar()

  const [deleteSowProject, { loading }] = useMutation(
    AdminDeleteScopeOfWorkProjectDocument
  )

  const inputConfigs: AnyInputConfig[] = [
    {
      name: 'confirmation',
      type: InputType.CustomComponent,
      props: {
        component: (
          <Typography>Are you sure you want to delete this project?</Typography>
        ),
      },
    },
  ]

  const onDelete = async () => {
    try {
      await deleteSowProject({
        variables: {
          input: {
            projectUuid: uuid,
          },
        },
        refetchQueries: [AdminSearchSowProjectsDocument],
      })
      notify('Project deleted successfully', 'success')
      close()
      window.location.reload()
    } catch (e) {
      notify('Failed to delete project', 'error')
    }
  }

  return (
    <GridFormModal
      title="Delete SOW Project"
      open={isOpen}
      onClose={close}
      onSubmit={onDelete}
      inputConfigs={inputConfigs}
      gridProps={{
        spacing: 3,
      }}
      formProps={{
        defaultValues: {},
      }}
      ctaText="Confirm"
      loading={loading}
    />
  )
}

export default DeleteSowProjectModal
