import React, { useState } from 'react'
import {
  CircleCheckedIcon,
  CircleUncheckedIcon,
  Dropdown,
  flockSquareTheme,
  LibraryThemeProvider,
  TrackedButton,
  useSnackbar,
} from '@flock/shared-ui'
import {
  Box,
  CircularProgress,
  CssBaseline,
  Modal,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material'
import {
  AdminCreateScopeOfWorkProjectDocument,
  AdminGetAllCostbooksDocument,
  AdminCreateSowProjectItemRelationshipDocument,
  Core_SowItem,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation, useQuery } from '@apollo/client'
import LabelledField from '../SowProjectsPage/IndividualSowProjectPage/LabelledField'
import { SOW_HOME } from '../../constants'

type CreateSowModalProps = {
  open: boolean
  onClose: () => void

  ledger: any
}

const CreateSowModal = (props: CreateSowModalProps) => {
  const { open, onClose, ledger } = props
  const [projectID, setProjectID] = useState('')
  const [scope, setScope] = useState('renovation')
  const [loading, setLoading] = useState(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [costbook, setCostbook] = useState('')
  const { notify } = useSnackbar()

  const handleItemClick = (uuid: string) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(uuid)) {
        return prevSelectedItems.filter((item) => item !== uuid)
      } else {
        return [...prevSelectedItems, uuid]
      }
    })
  }

  const [createSowProject] = useMutation(AdminCreateScopeOfWorkProjectDocument)
  const [createProjectItemRelationships] = useMutation(
    AdminCreateSowProjectItemRelationshipDocument
  )

  useQuery(AdminGetAllCostbooksDocument, {
    onCompleted: (data) => {
      const cbOptions = data?.getAllCostbooks?.costbooks?.map((cb) => ({
        label: cb?.name as string,
        value: cb?.uuid as string,
      }))
      if (cbOptions) {
        setCostbook(cbOptions[0].value)
      }
    },
  })

  const onSubmit = async () => {
    setLoading(true)
    const inputObj = {
      addressId: ledger.addressId,
      externalProjectId: projectID,
      externalProjectType: projectID === '' ? 'manual' : 'inside_maps',
      costbook,
      projectType: scope,
      inspectionDate: new Date().toISOString().split('T')[0],
    }
    try {
      const createProjectResponse = await createSowProject({
        variables: {
          input: inputObj,
        },
      })

      const projectUuid = createProjectResponse.data?.createSOWProject
        ?.uuid as string

      // for each selectedItem, create a relationship with the created project uuid
      await Promise.all(
        selectedItems.map((item) =>
          createProjectItemRelationships({
            variables: {
              input: {
                projectUuid,
                itemUuid: item,
              },
            },
          })
        )
      )

      notify('Project created successfully', 'success')
      window.location.href = `${SOW_HOME}/${projectUuid}`
    } catch (e) {
      notify(
        `Failed to create project. Please verify Project ID ${e.message}`,
        'error'
      )
    }
    setLoading(false)
  }

  return (
    <ThemeProvider theme={flockSquareTheme}>
      <CssBaseline />
      <LibraryThemeProvider theme={flockSquareTheme}>
        <Modal open={open} onClose={onClose}>
          <Box
            position="absolute"
            width="80%"
            maxHeight="800px"
            p="16px"
            sx={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'gray1.main',
              borderRadius: '8px',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="left"
              gap="8px"
              width="100%"
              maxHeight="500px"
              overflow="auto"
            >
              <Typography variant="c1m">DEFICIENCIES</Typography>
              {ledger?.openItems
                .filter(
                  (item: Core_SowItem, index: number, self: Core_SowItem[]) =>
                    index === self.findIndex((i) => i.uuid === item.uuid)
                )
                .map((item: Core_SowItem) => (
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    gap="16px"
                    borderRadius="16px"
                    p={2}
                    sx={{
                      backgroundColor: 'green0.main',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleItemClick(item.uuid)}
                  >
                    {selectedItems.includes(item.uuid) ? (
                      <CircleCheckedIcon width="16px" height="16px" />
                    ) : (
                      <CircleUncheckedIcon width="16px" height="16px" />
                    )}
                    <Box display="flex" width="25%" flexDirection="row">
                      <Typography variant="c1">{item.itemLocation}</Typography>
                    </Box>
                    <Box display="flex" width="25%" flexDirection="row">
                      <Typography variant="c1">
                        {item.category} | {item.deficiency}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              {(ledger?.openParentItems.length || 0) > 0 && (
                <>
                  <Typography variant="c1m">SHARED DEFICIENCIES</Typography>
                  {ledger?.openParentItems
                    .filter(
                      (
                        item: Core_SowItem,
                        index: number,
                        self: Core_SowItem[]
                      ) => index === self.findIndex((i) => i.uuid === item.uuid)
                    )
                    .map((item: Core_SowItem) => (
                      <Box
                        display="flex"
                        width="100%"
                        flexDirection="row"
                        alignItems="center"
                        borderRadius="16px"
                        p={2}
                        sx={{
                          backgroundColor: 'green0.main',
                        }}
                      >
                        <Typography variant="c1">
                          {item.itemLocation}
                        </Typography>
                        <Typography variant="c1">
                          {item.category} | {item.deficiency}
                        </Typography>
                      </Box>
                    ))}
                </>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="16px"
              p={2}
              mt="8px"
              sx={{
                backgroundColor: 'green0.main',
              }}
            >
              <Box>
                <LabelledField label="Project ID (Optional)">
                  <TextField
                    variant="filled"
                    value={projectID}
                    placeholder="W5FxyztxUl"
                    onChange={(e) => setProjectID(e.target.value)}
                  />
                </LabelledField>
              </Box>

              <Box>
                <LabelledField label="Scope">
                  <Box width="230px">
                    <Dropdown
                      variant="filled"
                      options={[
                        { label: 'Inspection', value: 'acquisition' },
                        { label: 'Renovation', value: 'renovation' },
                      ]}
                      value={scope}
                      onChange={(e) => setScope(e.target.value)}
                    />
                  </Box>
                </LabelledField>
              </Box>
              {loading ? (
                <CircularProgress size="28px" />
              ) : (
                <TrackedButton
                  variant="primary"
                  size="smallForm"
                  onClick={onSubmit}
                >
                  Create New Scope of Work
                </TrackedButton>
              )}
            </Box>
          </Box>
        </Modal>
      </LibraryThemeProvider>
    </ThemeProvider>
  )
}

export default CreateSowModal
